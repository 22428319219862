/*
TODO:
Refactoring required:
1. Column width for description - need to add max-width. Max-width needs to be calculated
based on available width and other columns width (also considering side nav status etc)

*/

import React, { useMemo } from 'react'
import { CellContext, ColumnDef } from '@tanstack/react-table'

import { IFilterValues } from 'forms/FormModelInterface'
import { OperatorWithDevicesFilterFn } from 'forms/FormUtils'
import { iOperatorInfo } from 'data/OperatorListHook'

import TsTable from 'components/table/TsTable'

import { DeviceStatus } from 'pages/devices-2/view/DeviceTableComponents'
import Tooltip from 'components/atom/Tooltip'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { UserRoleTypes } from 'utils/CommonEnums'
import { useUserHasRequiredRoles } from 'hooks'
import { OperatorsPageColumnIds } from './OperatorsPageUtils'
import AddOperatorButton from './AddOperatorButton'
import { RowActions } from './OperatorTableActions'

interface calibrationPageTableProps {
  tableData?: iOperatorInfo[]
  isLoading: boolean
  filterValues: IFilterValues[]
  updateFilteredData: (data: iOperatorInfo[]) => void
  onShowFilter: () => void
  onFilterFormSubmit: (filterValues: IFilterValues[]) => void
  onFilterFormReset: () => void
  setShowExportDialog: (val: boolean) => void
}

function OperatorsPageTable(tableDataProps: calibrationPageTableProps): JSX.Element {
  const {
    tableData,
    filterValues,
    isLoading,
    updateFilteredData,
    onShowFilter,
    onFilterFormReset,
    setShowExportDialog,
  } = tableDataProps
  const userHasRequiredRoles = useUserHasRequiredRoles([
    UserRoleTypes.User,
    UserRoleTypes.CustomerAdmin.split(' ').join(''),
    UserRoleTypes.Admin,
  ])
  const tableColumns = useMemo<ColumnDef<iOperatorInfo>[]>(
    () => [
      {
        id: OperatorsPageColumnIds.fullName,
        header: 'Name',
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <Tooltip
              showOnlyWhenTextIsTruncate
              id={`operator-name-${value}`}
              tooltipText={value}
              toolTipClass='tooltip'
              className={` font-bold text-2xs leading-5  text-ellipsis `}
            >
              {value}
            </Tooltip>
          )
        },
        meta: {},
        filterFn: 'includesString',
      },
      {
        id: OperatorsPageColumnIds.operatorId,
        header: 'ID',
        accessorKey: 'uniqueId',
        cell: (info) => {
          const operatorUniqueId = info.getValue() as string
          return (
            <div className='font-normal text-2xs leading-5  text-ellipsis '>{operatorUniqueId}</div>
          )
        },
        meta: {},
        filterFn: 'includesString',
      },

      {
        id: OperatorsPageColumnIds.businessUnit,
        header: 'Business Unit',
        accessorFn: (row) => `${row.businessUnit.name}`,
        cell: (info) => {
          const businessUnitName = info.getValue() as string
          return (
            <Tooltip
              id={`operator-business-unit-${businessUnitName}`}
              showOnlyWhenTextIsTruncate
              tooltipText={businessUnitName}
              toolTipClass='tooltip'
              className={` font-normal text-2xs leading-5  text-ellipsis `}
            >
              {businessUnitName}
            </Tooltip>
          )
        },
        meta: {},
        filterFn: 'includesString',
      },
      {
        id: OperatorsPageColumnIds.status,
        header: 'Status',
        accessorKey: 'status',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <div className=''>
              <DeviceStatus data={value} />
            </div>
          )
        },
        meta: {},
        filterFn: 'equalsString',
      },
      {
        id: OperatorsPageColumnIds.devicesInUse,
        header: 'Device(s) in use',
        accessorFn: (row) => `${row.currentDevice.length}`,
        cell: (info) => {
          const value: string = info.getValue() as string
          return <div className='py-3  text-2xs font-normal leading-5  text-ellipsis '>{value}</div>
        },
        meta: {},
        filterFn: OperatorWithDevicesFilterFn<iOperatorInfo>,
      },
      ...((userHasRequiredRoles
        ? [
            {
              id: '99',
              cell: (rowProps: CellContext<iOperatorInfo, unknown>) => (
                <div className={`flex flex-row gap-3 justify-end `}>
                  <RowActions rowData={rowProps.row.original} />
                </div>
              ),
              meta: {
                cellClassName: 'justify-end ',
                sortable: false,
                resizable: false,
                shouldDraggable: false,
              },
            },
          ]
        : []) as ColumnDef<iOperatorInfo>[]),
    ],
    [userHasRequiredRoles],
  )
  const renderFilterSummaryComponent = () => <> </>

  return (
    <TsTable<iOperatorInfo>
      columns={tableColumns}
      data={
        tableData?.map((row) => ({
          ...row,
          fullName: `${row.firstName} ${row.lastName}`,
          devicesInUse: `${row.currentDevice.length}`,
        })) ?? []
      }
      dataIsLoading={isLoading}
      showGlobalActionButton
      showGlobalFilter
      onExport={() => {}}
      onPrint={() => {}}
      getRowCanExpand={() => true}
      globalActionButton={<AddOperatorButton />}
      // renderSubComponent={renderSubComponent}
      updateFilteredData={(data) => updateFilteredData(data)}
      renderFilterSummary={renderFilterSummaryComponent}
      resetFilter={() => onFilterFormReset()}
      onShowFilter={() => onShowFilter()}
      filterValues={filterValues}
      minDisplayRows={10}
      defaultSortedColumKey={OperatorsPageColumnIds.operatorId}
      setShowExportDialog={(val) => setShowExportDialog(val)}
      // setFilterValues={setFilterValues}
      // dateRangeSelectorType={DateRangeSelectorTypes.DateRange}
      // dateRangeOptions={dateRangeOptions}
      // dateRangeFilterFn={DateRangeColumnFilterFn<iFaultData>}
    />
  )
}

export default withErrorBoundary(OperatorsPageTable, {
  FallbackComponent: ErrorPage,
})
