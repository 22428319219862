import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { isAfter, isBefore, isEqual, fromUnixTime, sub } from 'date-fns'
import { useDeviceDetails } from 'pages/deviceDetails/rootPage/view/DeviceDetailsRoot'
import { ColumnDef } from '@tanstack/react-table'
import { FormattedDate, FormattedTime, useIntl } from 'react-intl'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
import { DateRangeColumnFilterUnixTimeFn, dateRangeOptions, selectAllOption } from 'forms/FormUtils'
import TsTable from 'components/table/TsTable'
import { DateRangeSelectorTypes, DeviceDetailPageTabs } from 'utils/CommonEnums'
import { ScreenWidths, returnDateWithoutTimeOffset } from 'utils/Constants'
import {
  DeviceDetailContextActions,
  DeviceDetailsContext,
} from 'pages/deviceDetails/rootPage/view/DeviceDetailsContext'
import ExportData from 'exportReports/ExportData'
import Tooltip from 'components/atom/Tooltip'
import { withPageTracking } from 'utils/AppInsightConfig'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import DeviceDetailsReportSelector from 'pages/deviceDetails/rootPage/view/DeviceDetailsReportSelector'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { NavigateBackButton } from '../../../rootPage/view/NavigateBackButton'
import { iFaultData } from '../data/interface'
import { prepareFilterFormData } from '../data/DeviceFaultsDataUtils'
import { useDeviceFaultsData } from '../data/DeviceFaultsData'
import { FaultsReportColumns } from '../export/FaultsReportFormat'
import FaultFilterFormat from '../export/FaultsFilterFormat'

export const columnIDs = {
  faultType: 'eventType',
  faultDescription: 'eventDescription',
  faultDateTime: 'timeStamp',
  assignedTo: 'deviceUserName',
}

export type ReportFilterFormType = {
  faultDateSelector: IselectOptionProps
  faultDate: DateRange
  faultType: IselectOptionProps
  faultDescription: IselectOptionProps
  deviceUserName: IselectOptionProps
}

// faultDescription: string
// eventType: string
// deviceUserId: string
// deviceUserName: string
// uniqueId: string
// deviceUserStatus: UserStatusTypes

export const DeviceFaultsPageFilterParamLabels = {
  dateRangeFrom: 'from',
  dateRangeTo: 'to',
  filterDateFrom: 'fdatefrom',
  filterDateTo: 'fdateto',

  faultType: 'ftyp',
  faultDescription: 'fdesc',
  deviceUserName: 'user',
  deviceUserUniqueID: 'uniqueid',
  status: 'status',
}
function DeviceFaults() {
  // Implement the component logic here

  const [is2xlAndAbove, setIs2xlAndAbove] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIs2xlAndAbove(window.innerWidth >= ScreenWidths.xxl)
    }

    handleResize() // Initial check

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const deviceDetailsContext = useContext(DeviceDetailsContext)
  const { dispatch } = deviceDetailsContext

  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`

  const intl = useIntl()
  const [searchParams, setSearchParams] = useSearchParams()

  /**
   * Get the from and to dates from the URL
   * @param {string} from - The from date
   * @param {string} to - The to date
   * @param {string} filterDateTo - The to date for filter
   * @param {string} filterDateFrom - The from date for filter
   */
  const isToDate =
    searchParams.get('to') || searchParams.get(DeviceFaultsPageFilterParamLabels.filterDateTo)
  const isFromDate =
    searchParams.get('from') || searchParams.get(DeviceFaultsPageFilterParamLabels.filterDateFrom)

  const filterLogsDateTo = returnDateWithoutTimeOffset(isToDate || new Date())
  const filterLogsDateFrom = returnDateWithoutTimeOffset(
    isFromDate || sub(new Date(filterLogsDateTo), { months: 6 }),
  )

  const faultDescriptionParam =
    searchParams.get(DeviceFaultsPageFilterParamLabels.faultDescription) ?? ''
  const deviceUserParam = searchParams.get(DeviceFaultsPageFilterParamLabels.deviceUserName) ?? ''
  const faultTypeParam = searchParams.get(DeviceFaultsPageFilterParamLabels.faultType) ?? ''

  const params = useParams()
  const { deviceSerialNumber } = params as { deviceSerialNumber: string }
  document.title = `Service History ${deviceSerialNumber}`

  const { deviceDetails } = useDeviceDetails()
  const { id: deviceId } = deviceDetails ?? { deviceId: '' }

  const [filterFormDescriptionValues, setFilterFormDescriptionValues] = React.useState<
    IselectOptionProps[]
  >([])

  const [filterFormDeviceUserValues, setFilterFormDeviceUserValues] = React.useState<
    IselectOptionProps[]
  >([])

  const [filterFormFaultTypes, setFilterFormFaultTypes] = React.useState<IselectOptionProps[]>([])
  const [showExportDialog, setShowExportDialog] = React.useState(false)

  const [faultsDataTranslated, setFaultsDataTranslated] = React.useState<iFaultData[]>([])

  const { genericEventHandler } = useGenericEventHandler()
  const {
    data: faultsData,
    isLoading: isLoadingFaultsData,
    isError: isErrorFaultsData,
    error: errorFaultsData,
  } = useDeviceFaultsData(
    deviceId as string,
    filterLogsDateFrom.toISOString().split('T')[0],
    filterLogsDateTo.toISOString().split('T')[0],
    redirectPageURL,
    true,
  )

  useEffect(() => {
    if (isErrorFaultsData) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        message: errorFaultsData?.message || 'Error getting device faults',
        error: errorFaultsData,
        extraData: {
          component: 'DeviceFaults',
          action: 'get device faults',
        },
      })
    }
  }, [isErrorFaultsData, errorFaultsData])

  useEffect(() => {
    if (faultsData) {
      const translatedReportData: iFaultData[] = []

      faultsData.forEach((dataRow) => {
        const { eventDescription } = dataRow
        const translationKey = eventDescription as string
        if (!dataRow.eventLogValues) {
          const translatedDescription = intl.formatMessage({ id: translationKey })
          translatedReportData.push({
            ...dataRow,
            eventType: intl.formatMessage({
              id: `Eventlog.FaultTypes.${dataRow.eventType}`,
            }),
            eventDescription: translatedDescription,
          })
        } else {
          const translationValues: Record<string, string> = JSON.parse(dataRow.eventLogValues)
          translatedReportData.push({
            ...dataRow,
            eventType: intl.formatMessage({
              id: `Eventlog.FaultTypes.${dataRow.eventType}`,
            }),
            eventDescription: intl.formatMessage(
              {
                id: `${dataRow.eventDescription}`,
              },
              translationValues,
            ),
          })
        }
      })

      setFaultsDataTranslated(translatedReportData)
    }
  }, [faultsData])

  useEffect(() => {
    if (faultsDataTranslated && faultsDataTranslated.length > 0) {
      const { faultTypes, faultDescriptions, faultDeviceUsers } =
        prepareFilterFormData(faultsDataTranslated)

      setFilterFormFaultTypes(faultTypes)
      setFilterFormDescriptionValues(faultDescriptions)
      setFilterFormDeviceUserValues(faultDeviceUsers)
    }
  }, [faultsDataTranslated])

  const fetchedReportDataColumns = useMemo<ColumnDef<iFaultData>[]>(
    () => [
      {
        id: columnIDs.faultDateTime,
        header: 'Date and time',
        accessorKey: 'timeStamp',
        cell: (info) => {
          const value: number = info.getValue() as number
          const date = fromUnixTime(value)
          return (
            <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
              <span className='mr-2'>
                <FormattedDate value={date} />
              </span>
              <span>
                <FormattedTime value={date} />
              </span>
            </div>
          )
        },

        meta: {},
        filterFn: DateRangeColumnFilterUnixTimeFn<iFaultData>,
        // filterFn: DateRangeColumnFilterFn<iFaultData>,
      },
      {
        id: columnIDs.faultType,
        header: 'Fault Type',
        accessorKey: 'eventType',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <Tooltip
              id={`device-faults-fault-type-${value}`}
              showOnlyWhenTextIsTruncate
              tooltipText={value}
              toolTipClass='tooltip'
              className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1  text-ellipsis '
            >
              {value}
            </Tooltip>
          )
        },
        meta: {},
        filterFn: 'includesString',
      },
      {
        id: columnIDs.faultDescription,
        header: 'Description',
        accessorKey: 'eventDescription',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <Tooltip
              id={`device-faults-fault-description-${value}`}
              showOnlyWhenTextIsTruncate
              tooltipText={value}
              toolTipClass='tooltip'
              className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1  text-ellipsis '
            >
              {value}
            </Tooltip>
          )
        },
        meta: {},
        filterFn: 'includesString',
      },

      {
        id: columnIDs.assignedTo,
        header: 'Assigned to',
        accessorKey: 'deviceUserName',
        meta: {},
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <Tooltip
              id={`device-faults-assigned-to-${value}`}
              showOnlyWhenTextIsTruncate
              tooltipText={value}
              toolTipClass='tooltip'
              className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1  text-ellipsis '
            >
              {value}
            </Tooltip>
          )
        },
        filterFn: () => true,
      },
    ],
    [],
  )

  const resetFilterFormParams = () => {
    searchParams.delete(DeviceFaultsPageFilterParamLabels.filterDateFrom)
    searchParams.delete(DeviceFaultsPageFilterParamLabels.filterDateTo)
    searchParams.delete(DeviceFaultsPageFilterParamLabels.faultDescription)
    searchParams.delete(DeviceFaultsPageFilterParamLabels.deviceUserName)
    searchParams.delete(DeviceFaultsPageFilterParamLabels.deviceUserUniqueID)
    searchParams.delete(DeviceFaultsPageFilterParamLabels.status)
    searchParams.delete(DeviceFaultsPageFilterParamLabels.faultType)
    setSearchParams(searchParams)
  }

  const handleFilterFormSubmit = useCallback(
    (formData: IFilterValues[]) => {
      formData.forEach((filter) => {
        if (filter.columnId === columnIDs.faultDescription && filter?.value !== null) {
          if (filter.value === '') {
            searchParams.delete(DeviceFaultsPageFilterParamLabels.faultDescription)
          } else {
            searchParams.set(
              DeviceFaultsPageFilterParamLabels.faultDescription,
              filter.value as string,
            )
          }
        }

        if (filter.columnId === columnIDs.assignedTo) {
          if (filter.value === '') {
            searchParams.delete(DeviceFaultsPageFilterParamLabels.deviceUserName)
          } else {
            searchParams.set(
              DeviceFaultsPageFilterParamLabels.deviceUserName,
              filter.value as string,
            )
          }
        }

        if (filter.columnId === columnIDs.faultType) {
          if (filter.value === '') {
            searchParams.delete(DeviceFaultsPageFilterParamLabels.faultType)
          } else {
            searchParams.set(DeviceFaultsPageFilterParamLabels.faultType, filter.value as string)
          }
        }

        if (filter.columnId === columnIDs.faultDateTime) {
          if (filter.value === '') {
            searchParams.delete(DeviceFaultsPageFilterParamLabels.dateRangeFrom)
            searchParams.delete(DeviceFaultsPageFilterParamLabels.dateRangeTo)
            searchParams.delete(DeviceFaultsPageFilterParamLabels.filterDateFrom)
            searchParams.delete(DeviceFaultsPageFilterParamLabels.filterDateTo)
          } else {
            const dateRangeToBeFiltered = filter?.value as DateRange
            // Calculate if DateRange is wider based on below code
            const isFromBeforeOrSame =
              isBefore(new Date(dateRangeToBeFiltered.startDate), new Date(isFromDate as string)) ||
              isEqual(new Date(dateRangeToBeFiltered.startDate), new Date(isFromDate as string))

            const isToAfterOrSame =
              isAfter(new Date(dateRangeToBeFiltered.endDate), new Date(isToDate as string)) ||
              isEqual(new Date(dateRangeToBeFiltered.endDate), new Date(isToDate as string))

            const isDateRangeWider = isFromBeforeOrSame && isToAfterOrSame

            if (isDateRangeWider) {
              searchParams.set(
                DeviceFaultsPageFilterParamLabels.dateRangeFrom,
                new Date(dateRangeToBeFiltered.startDate).toISOString().split('T')[0],
              )

              searchParams.set(
                DeviceFaultsPageFilterParamLabels.dateRangeTo,
                new Date(dateRangeToBeFiltered.endDate).toISOString().split('T')[0],
              )
            }

            searchParams.set(
              DeviceFaultsPageFilterParamLabels.filterDateFrom,
              new Date(dateRangeToBeFiltered.startDate).toISOString().split('T')[0],
            )

            searchParams.set(
              DeviceFaultsPageFilterParamLabels.filterDateTo,
              new Date(dateRangeToBeFiltered.endDate).toISOString().split('T')[0],
            )
          }
        }
      })
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  const filterTableValues = useMemo(
    () => [
      {
        columnId: columnIDs.faultDescription,
        value: faultDescriptionParam ?? '',
      },
      {
        columnId: columnIDs.assignedTo,
        value: deviceUserParam ?? '',
      },
      {
        columnId: columnIDs.faultType,
        value: faultTypeParam ?? '',
      },
      {
        columnId: columnIDs.faultDateTime,
        value:
          isFromDate && isToDate
            ? {
                startDate: new Date(filterLogsDateFrom),
                endDate: new Date(filterLogsDateTo),
              }
            : '',
      },
    ],
    [faultDescriptionParam, deviceUserParam, filterLogsDateFrom, filterLogsDateTo, faultTypeParam],
  )

  const convertISelectOptionPropsToFormType = useMemo(() => {
    const eventDescriptionFiltered =
      filterTableValues.find((filter) => filter.columnId === columnIDs.faultDescription)?.value ??
      ''

    const dateRangeFiltered = (filterTableValues.find(
      (filter) => filter.columnId === columnIDs.faultDateTime,
    )?.value || {
      startDate: filterLogsDateFrom,
      endDate: filterLogsDateTo,
    }) as DateRange

    const deviceUserFiltered =
      filterTableValues.find((filter) => filter.columnId === columnIDs.assignedTo)?.value ?? ''

    const faultTypeFiltered =
      filterTableValues.find((filter) => filter.columnId === columnIDs.faultType)?.value ?? ''

    const r: ReportFilterFormType = {
      faultDateSelector: dateRangeOptions.find(
        (option) => option.value === DateRangeSelectorTypes.Custom,
      ) ?? {
        label: '',
        value: '',
      },

      faultDate: {
        startDate: new Date(dateRangeFiltered.startDate),
        endDate: new Date(dateRangeFiltered.endDate),
      },

      faultDescription: {
        value: eventDescriptionFiltered,
        label: eventDescriptionFiltered as string,
      },
      deviceUserName: {
        value: deviceUserFiltered,
        label: deviceUserFiltered as string,
      },
      faultType:
        faultTypeFiltered === ''
          ? selectAllOption
          : {
              value: faultTypeFiltered as string,
              label: faultTypeFiltered as string,
            },
    }
    return r
  }, [
    filterTableValues,
    dateRangeOptions,
    deviceUserParam,
    faultDescriptionParam,
    filterLogsDateFrom,
    filterLogsDateTo,
    faultTypeParam,
  ])

  const showFilter = () => {
    dispatch({
      type: DeviceDetailContextActions.ShowFilterForm,
      payload: {
        page: DeviceDetailPageTabs.FaultsLog,
        filterFormData: {
          onFilterSubmit: (formData: IFilterValues[]) => handleFilterFormSubmit(formData),
          onFilterReset: () => resetFilterFormParams(),
          // onFilterFormClose: () => setShowFilterForm(false),
          selectedValues: convertISelectOptionPropsToFormType,
          deviceUsersData: filterFormDeviceUserValues,
          faultDescriptionsData: filterFormDescriptionValues,
          faultTypesData: filterFormFaultTypes,
        },
      },
    })
  }

  const hideFilterForm = () => {
    dispatch({ type: DeviceDetailContextActions.HideFilterForm })
  }

  // useEffect(() => {
  //   if (is2xlAndAbove) {
  //     showFilter()
  //   } else {
  //     hideFilterForm()
  //   }
  // }, [is2xlAndAbove])

  // Return the JSX that will be rendered
  return (
    <>
      {/* <div className={clsx('flex flex-row ', state.filterFormShown && '')}> */}
      <ExportData
        handleCancel={() => setShowExportDialog(false)}
        IsOpen={showExportDialog}
        data={faultsDataTranslated ?? []}
        reportColumns={FaultsReportColumns}
        widgetForReport={[]}
        reportTitle='Device faults'
        filterSummary={FaultFilterFormat(
          filterTableValues,
          deviceDetails?.serialNumber || '',
          deviceDetails?.deviceType || '',
        )}
      />
      {/* <div className='flex-grow overflow-x-auto'> */}
      <TsTable<iFaultData>
        data={faultsDataTranslated ?? []}
        dataIsLoading={isLoadingFaultsData}
        columns={fetchedReportDataColumns}
        showGlobalActionButton={false}
        globalActionButton={<NavigateBackButton />}
        showGlobalFilter={false}
        getRowCanExpand={() => false}
        onPrint={() => {}}
        onExport={() => {}}
        updateFilteredData={() => {}}
        renderFilterSummary={() => <> </>}
        filterValues={filterTableValues}
        onShowFilter={() => showFilter()}
        resetFilter={() => resetFilterFormParams()}
        minDisplayRows={10}
        defaultSortedColumKey={columnIDs.faultDateTime}
        renderTabs={() => <DeviceDetailsReportSelector />}
        setShowExportDialog={(val) => setShowExportDialog(val)}
      />
      {/* </div> */}
      {/* <ReportFilterForm
          onFilterSubmit={filterReportData}
          onFilterReset={() => {}}
          onFilterFormClose={() => {}}
          selectedValues={filterFormSelectedValues}
          deviceUserNames={filterFormDeviceUserValues}
          faultTypes={filterFormFaultTypes}
          faultDescriptions={filterFormDescriptionValues}
        /> */}
      {/* </div> */}
    </>
  )
}

export default withErrorBoundary(withPageTracking(DeviceFaults), {
  FallbackComponent: ErrorPage,
})
