import { Popover } from '@headlessui/react'
import { ColumnIcon } from 'assets/icons'
import Show from 'components/atom/Show'
import React, { useCallback, useContext, useEffect } from 'react'
import Tooltip from 'components/atom/Tooltip'
import { useLocation } from 'react-router-dom'
import { TableContext, TableContextType } from '../../TableContext'

export default function ColumnVisibility<T>() {
  // Use the TableContext to get the table instance
  const { table } = useContext<TableContextType<T>>(TableContext)
  const location = useLocation()
  const columnVisibilityHelper = useCallback(() => {
    const visibleColumn = table.getState().columnVisibility

    if (visibleColumn) {
      localStorage.setItem(
        'columnVisibility',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('columnVisibility') as string),
          [location.pathname]: visibleColumn,
        }),
      )

      table.getFilteredRowModel().rows.map((row) => {
        const originalRow = row.original as Record<string, unknown>
        const dummyRow = { ...row.original } as Record<string, unknown>

        Object.keys(originalRow).forEach((key) => {
          if (visibleColumn[key] === false) {
            delete dummyRow[key]
          }
        })

        return dummyRow
      })
    }
  }, [table.getState().columnVisibility])

  useEffect(() => {
    columnVisibilityHelper()
  }, [columnVisibilityHelper])

  return (
    // Show component is used to conditionally render its children
    <Show>
      {/* Only render the children if the table has rows */}
      <Show.When isTrue={Boolean(table.getRowModel().rows.length !== 0)}>
        {/* Popover component provides a dropdown-like functionality */}
        <Popover className='relative float-right'>
          {/* The button that triggers the popover */}
          <Tooltip
            tooltipText='Column'
            toolTipClass='tooltip z-50 px-2.5 -top-8  w-fit -left-2 text-center w-auto m-auto'
          >
            <Popover.Button className='p-3 bg-c-dark-blue-1 rounded-full text-white flex items-center outline-0 hover:bg-c-dark-blue-2 active:bg-c-dark-blue-3'>
              {/*  Icon for the button */}
              <ColumnIcon fill='#fff' width={16} height={16} />
            </Popover.Button>
          </Tooltip>
          {/*  The panel that appears when the button is clicked */}
          <Popover.Panel className='absolute z-10 right-0 mt-1 bg-c-light-blue-2 rounded-md space-2 p-2 drop-shadow-lg'>
            {/* Map over all leaf columns in the table */}
            {table.getAllLeafColumns().map((column) => (
              // Show component is used to conditionally render its children
              <Show key={column.id}>
                {/*  Only render the children if the column header is a string and the column is not forced to be hidden */}
                <Show.When
                  isTrue={
                    typeof column.columnDef.header === 'string' &&
                    !column.columnDef.meta?.forcedHidden
                  }
                >
                  {/* Checkbox and label for each column */}
                  <div className='relative flex items-start min-w-max hover:bg-white px-4 py-2 hover:rounded-full w-full '>
                    <div className='flex h-6 items-center '>
                      {/*  Checkbox to toggle column visibility */}
                      <input
                        type='checkbox'
                        checked={column.getIsVisible()}
                        onChange={column.getToggleVisibilityHandler()}
                        id={column.id}
                        aria-describedby='comments-description'
                        name='comments'
                        className='h-4 w-4 rounded border-c-blue text-c-dark-blue-1 focus:ring-c-dark-blue-1'
                      />
                    </div>
                    {/* Label for the checkbox */}
                    <label
                      htmlFor={column.id}
                      className='ml-3 text-sm leading-6 text-c-blue select-none w-full'
                    >
                      {column.columnDef.header?.toString() ?? ''}
                    </label>
                  </div>
                </Show.When>
              </Show>
            ))}
          </Popover.Panel>
        </Popover>
      </Show.When>
    </Show>
  )
}
