import React, { useEffect, useMemo, useContext, useCallback, useState } from 'react'
import { useParams, useSearchParams, useLocation } from 'react-router-dom'
import { fromUnixTime, sub } from 'date-fns'
import { useDeviceDetails } from 'pages/deviceDetails/rootPage/view/DeviceDetailsRoot'
import { ColumnDef } from '@tanstack/react-table'
import { FormattedDate, FormattedTime } from 'react-intl'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
import { DateRangeColumnFilterFn, dateRangeOptions, selectAllOption } from 'forms/FormUtils'
import TsTable from 'components/table/TsTable'
import { DateRangeSelectorTypes, DeviceDetailPageTabs } from 'utils/CommonEnums'
import { MaxNumberOfChannels, ScreenWidths, returnDateWithoutTimeOffset } from 'utils/Constants'
import {
  DeviceDetailContextActions,
  DeviceDetailsContext,
} from 'pages/deviceDetails/rootPage/view/DeviceDetailsContext'
import ExportData from 'exportReports/ExportData'
import { withPageTracking } from 'utils/AppInsightConfig'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import DeviceDetailsReportSelector from 'pages/deviceDetails/rootPage/view/DeviceDetailsReportSelector'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { GasLogsReportColumns } from '../export/GasLogsReportFormat'
import GasLogFilterFormat from '../export/GasLogsFilterFormat'

import { NavigateBackButton } from '../../../rootPage/view/NavigateBackButton'
import { iSingleGasLogTabular } from '../data/interface'
import {
  iDevieGasLogsAPIParams,
  useDeviceGasLogsData,
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  EXPORT_FILE_PAGE_SIZE,
} from '../data/DeviceGasLogData'
import { useDeviceGasUoMList } from '../data/DeviceGasLogSupportData'

export const columnIDs = {
  timeStamp: 'timeStamp',
  channelNumber: 'channelNumber',
  gasName: 'gasName',
  gasValueUoM: 'gasValueUoM',

  gasValue: 'gasValue', // hidden column for filtering only
  gasUnit: 'gasUnit', // hidden column for filtering only

  // gasData: 'gasData',
  // gasTypes: ['gasType1', 'gasType2', 'gasType3', 'gasType4', 'gasType5'],
  // gasLevels: ['gasLevel1', 'gasLevel2', 'gasLevel3', 'gasLevel4', 'gasLevel5'],
}

const DeviceGasLogFilterParamLabels = {
  dateRangeFrom: 'from',
  dateRangeTo: 'to',
  filterDateFrom: 'fdatefrom',
  filterDateTo: 'fdateto',
  channelNumber: 'ch',
  gasName: 'gasname',
  gasValue: 'gasval',
  gasUnit: 'gasunit',

  pageNumber: 'pagenum',
  pageSize: 'pagesize',
}

export type DeviceGasLogsFilterFormType = {
  dateRangeSelector: IselectOptionProps
  dateRange: DateRange
  channelNumber: IselectOptionProps
  gasName: IselectOptionProps
  gasValue: string
  gasUnit: IselectOptionProps
}

function DeviceGasLogs() {
  // Implement the component logic here

  const [is2xlAndAbove, setIs2xlAndAbove] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIs2xlAndAbove(window.innerWidth >= ScreenWidths.xxl)
    }

    handleResize() // Initial check

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const deviceDetailsContext = useContext(DeviceDetailsContext)
  const { dispatch } = deviceDetailsContext

  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`

  const [searchParams, setSearchParams] = useSearchParams()
  // const [filterValues, setFilterValues] = React.useState<IFilterValues[]>([])

  const [channelNumbersToFilter, setChannelNumbersToFilter] = React.useState<IselectOptionProps[]>([
    selectAllOption,
  ])
  const [gasNamesToFilter, setGasNamesToFilter] = React.useState<IselectOptionProps[]>([
    selectAllOption,
  ])
  const [gasUnitsToFilter, setGasUnitsToFilter] = React.useState<IselectOptionProps[]>([
    selectAllOption,
  ])
  // const [gasValuesToFilter, setGasValuesToFilter] = React.useState<IselectOptionProps[]>([
  //   selectAllOption,
  // ])

  /**
   * Get the from and to dates from the URL
   * @param {string} isFromDate - The from date from the URL
   * @param {string} isToDate - The to date from the URL
   * @param {string} filterDateFrom - The filter date from the URL
   * @param {string} filterDateTo - The filter date from the URL
   */
  const isFromDate =
    searchParams.get(DeviceGasLogFilterParamLabels.dateRangeFrom) ||
    searchParams.get(DeviceGasLogFilterParamLabels.filterDateFrom)
  const isToDate =
    searchParams.get(DeviceGasLogFilterParamLabels.dateRangeTo) ||
    searchParams.get(DeviceGasLogFilterParamLabels.filterDateTo)

  const filterDateTo = returnDateWithoutTimeOffset(isToDate || new Date())
  const filterDateFrom = returnDateWithoutTimeOffset(
    isFromDate || sub(new Date(filterDateTo), { months: 6 }),
  )
  // channelNumber: IselectOptionProps
  // gasName: IselectOptionProps
  // gasValue: IselectOptionProps
  // gasUnit: IselectOptionProps

  const channelNumberParam = searchParams.get(DeviceGasLogFilterParamLabels.channelNumber) ?? ''
  const gasNameParam = searchParams.get(DeviceGasLogFilterParamLabels.gasName) ?? ''
  // const gasValueParam = searchParams.get(DeviceGasLogFilterParamLabels.gasValue) ?? ''
  const gasUnitParam = searchParams.get(DeviceGasLogFilterParamLabels.gasUnit) ?? ''

  const pageIndexParam = searchParams.get(DeviceGasLogFilterParamLabels.pageNumber) ?? '0'
  const pageSizeParam = searchParams.get(DeviceGasLogFilterParamLabels.pageSize) ?? '10'

  const params = useParams()
  const { deviceSerialNumber } = params as { deviceSerialNumber: string }
  document.title = `Gas Logs ${deviceSerialNumber}`

  const { deviceDetails } = useDeviceDetails()
  const { id: deviceId } = deviceDetails ?? { deviceId: '' }
  const [showExportDialog, setShowExportDialog] = useState(false)

  const { genericEventHandler } = useGenericEventHandler()
  // const reportAPIParams: iDevieGasLogsAPIParams = {
  //   deviceId: deviceId ?? '',
  //   startDate: isFromDate ?? '',
  //   endDate: isToDate ?? '',
  // }

  // const [reportFromToDates, setReportFromToDates] = React.useState({
  //   startDate: isFromDate ?? '',
  //   endDate: isToDate ?? '',
  // })

  const {
    data: gasLogsData,
    isLoading: gasLogsDataLoading,
    isError: isGasLogsDataError,
    error: gasLogsDataError,
  } = useDeviceGasLogsData(
    redirectPageURL,
    deviceId as string,
    filterDateFrom.toISOString().split('T')[0],
    filterDateTo.toISOString().split('T')[0],
    channelNumberParam ? Number(channelNumberParam) : undefined,
    gasNameParam,
    // gasValueParam ? Number(gasValueParam) : undefined,
    gasUnitParam,
    pageIndexParam ? Number(pageIndexParam) : DEFAULT_PAGE_INDEX,
    pageSizeParam ? Number(pageSizeParam) : DEFAULT_PAGE_SIZE,
    true,
  )

  const {
    data: exportGasLogsData,
    // isLoading: exportGasLogsDataLoading,
    isSuccess: isExportGasLogsDataSuccess,
    isError: isExportGasLogsDataError,
    error: exportGasLogsDataError,
  } = useDeviceGasLogsData(
    redirectPageURL,
    deviceId as string,
    filterDateFrom.toISOString().split('T')[0],
    filterDateTo.toISOString().split('T')[0],
    channelNumberParam ? Number(channelNumberParam) : undefined,
    gasNameParam,
    gasUnitParam,
    DEFAULT_PAGE_INDEX,
    EXPORT_FILE_PAGE_SIZE,
    true,
  )

  const {
    data: gasUoMList,
    // isLoading: gasUoMListLoading,
    isError: isGasUoMListError,
    error: gasUoMListError,
  } = useDeviceGasUoMList(redirectPageURL, deviceId as string, true)

  useEffect(() => {
    if (isGasLogsDataError) {
      genericEventHandler({
        error: gasLogsDataError,
        severity: 'error',
        onlyTrack: true,
        message: gasLogsDataError?.message || 'Error fetching gas logs data',
        extraData: {
          component: 'DeviceGasLogs',
          action: 'fetching gas logs data',
        },
      })
    }

    if (isGasUoMListError) {
      genericEventHandler({
        error: gasUoMListError,
        severity: 'error',
        onlyTrack: true,
        message: gasUoMListError?.message || 'Error fetching gas UoM list',
        extraData: {
          component: 'DeviceGasLogs',
          action: 'fetching gas UoM list',
        },
      })
    }

    if (isExportGasLogsDataError) {
      genericEventHandler({
        error: exportGasLogsDataError,
        severity: 'error',
        onlyTrack: true,
        message: exportGasLogsDataError?.message || 'Error fetching export gas logs data',
        extraData: {
          component: 'DeviceGasLogs',
          action: 'fetching export gas logs data',
        },
      })
    }
  }, [
    isGasLogsDataError,
    gasLogsDataError,
    isGasUoMListError,
    gasUoMListError,
    isExportGasLogsDataError,
    exportGasLogsDataError,
  ])

  useEffect(() => {
    if (gasUoMList) {
      const gasNameOptions: IselectOptionProps[] = []
      gasUoMList.gasTypes.forEach((gasType) => {
        gasNameOptions.push({
          label: gasType,
          value: gasType,
        })
      })
      gasNameOptions.unshift(selectAllOption)
      setGasNamesToFilter(gasNameOptions)

      const gasUnitOptions: IselectOptionProps[] = []
      gasUoMList.uoMs.forEach((uom) => {
        gasUnitOptions.push({
          label: uom,
          value: uom,
        })
      })
      gasUnitOptions.unshift(selectAllOption)
      setGasUnitsToFilter(gasUnitOptions)
    }
  }, [gasUoMList])

  useEffect(() => {
    if (deviceDetails) {
      const maxChannels = MaxNumberOfChannels[deviceDetails.deviceType]
      const channels: IselectOptionProps[] = []
      for (let i = 1; i <= maxChannels; i += 1) {
        channels.push({
          label: i.toString(),
          value: i.toString(),
        })
      }
      channels.unshift(selectAllOption)
      setChannelNumbersToFilter(channels)
    }
  }, [deviceDetails])

  const gasLogsTableColumns = useMemo<ColumnDef<iSingleGasLogTabular>[]>(
    () => [
      {
        id: columnIDs.timeStamp,
        header: 'Date & Time',
        accessorKey: 'timeStamp',
        cell: (info) => {
          const value: number = info.getValue() as number
          const date = fromUnixTime(value)
          return (
            <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 text-ellipsis '>
              <span className='mr-2'>
                <FormattedDate value={date} />
              </span>
              <span>
                <FormattedTime value={date} hour='2-digit' minute='2-digit' second='2-digit' />
              </span>
            </div>
          )
        },
        meta: {},
        filterFn: DateRangeColumnFilterFn<iSingleGasLogTabular>,
      },
      {
        id: columnIDs.channelNumber,
        header: 'Channel number',
        accessorKey: 'channelNumber',
        cell: (info) => {
          const value: number = info.getValue() as number
          return (
            <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 text-ellipsis '>
              {value}
            </div>
          )
        },
        meta: {},
      },
      {
        id: columnIDs.gasName,
        header: 'Gas name',
        accessorKey: 'gasName',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 text-ellipsis '>
              {value}
            </div>
          )
        },
        meta: {},
      },
      {
        id: columnIDs.gasValueUoM,
        header: 'Gas value (UoM)',
        // accessorKey: 'gasValueUoM',
        accessorFn: (data) => {
          const { gasValue } = data
          const gasUoM = data.uom
          return `${gasValue} ${gasUoM}`
        },
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 text-ellipsis '>
              {value}
            </div>
          )
        },
        meta: {},
      },

      // {
      //   id: columnIDs.gasData,
      //   header: 'Gas data',
      //   meta: {
      //     headerClassName: 'justify-center',
      //   },
      //   columns: [
      //     {
      //       id: 'Gasdata1',
      //       header: 'Channel 1',
      //       meta: {
      //         headerClassName: 'justify-center',
      //       },
      //       columns: [
      //         {
      //           id: columnIDs.gasTypes[0],
      //           header: 'Gas type',
      //           accessorKey: 'gasValues',
      //           enableSorting: false,
      //           cell: (info) => {
      //             const gasValue: iSingleGasValue[] = info.getValue() as iSingleGasValue[]
      //             const { name } = gasValue[0] ?? { name: '' }
      //             return (
      //               <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
      //                 {name}
      //               </div>
      //             )
      //           },
      //         },
      //         {
      //           id: columnIDs.gasLevels[0],
      //           header: 'Gas value',
      //           accessorKey: 'gasValues',
      //           cell: (info) => {
      //             const gasValue: iSingleGasValue[] = info.getValue() as iSingleGasValue[]
      //             const { value, uom } = gasValue[0] ?? { value: '', uom: '' }
      //             return (
      //               <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
      //                 {`${value} ${uom}`}
      //               </div>
      //             )
      //           },
      //         },
      //       ],
      //     },

      //     {
      //       id: 'Gasdata2',
      //       header: 'Channel 2',
      //       enableHiding: true,
      //       meta: {
      //         headerClassName: 'justify-center',
      //       },
      //       columns: [
      //         {
      //           id: columnIDs.gasTypes[1],
      //           header: 'Gas type',
      //           accessorKey: 'gasValues',
      //           enableSorting: false,
      //           cell: (info) => {
      //             // info.column.toggleVisibility(true)
      //             const gasValue: iSingleGasValue[] = info.getValue() as iSingleGasValue[]
      //             const { name } = gasValue[1] ?? { name: '' }
      //             return (
      //               <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
      //                 {name}
      //               </div>
      //             )
      //           },
      //         },
      //         {
      //           id: columnIDs.gasLevels[1],
      //           header: 'Gas value',
      //           accessorKey: 'gasValues',
      //           cell: (info) => {
      //             const gasValue: iSingleGasValue[] = info.getValue() as iSingleGasValue[]
      //             const { value, uom } = gasValue[1] ?? { value: '', uom: '' }
      //             return (
      //               <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
      //                 {`${value} ${uom}`}
      //               </div>
      //             )
      //           },
      //         },
      //       ],
      //     },

      //     {
      //       id: 'Gasdata3',
      //       header: 'Channel 3',
      //       meta: {
      //         headerClassName: 'justify-center',
      //       },
      //       columns: [
      //         {
      //           id: columnIDs.gasTypes[2],
      //           header: 'Gas type',
      //           accessorKey: 'gasValues',
      //           enableSorting: false,
      //           cell: (info) => {
      //             const gasValue: iSingleGasValue[] = info.getValue() as iSingleGasValue[]
      //             const { name } = gasValue[2] ?? { name: '' }
      //             return (
      //               <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
      //                 {name}
      //               </div>
      //             )
      //           },
      //         },
      //         {
      //           id: columnIDs.gasLevels[2],
      //           header: 'Gas value',
      //           accessorKey: 'gasValues',
      //           cell: (info) => {
      //             const gasValue: iSingleGasValue[] = info.getValue() as iSingleGasValue[]
      //             const { value, uom } = gasValue[2] ?? { value: '', uom: '' }
      //             return (
      //               <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
      //                 {`${value} ${uom}`}
      //               </div>
      //             )
      //           },
      //         },
      //       ],
      //     },

      //     {
      //       id: 'Gasdata4',
      //       header: 'Channel 4',
      //       meta: {
      //         headerClassName: 'justify-center',
      //       },
      //       columns: [
      //         {
      //           id: columnIDs.gasTypes[3],
      //           header: 'Gas type',
      //           accessorKey: 'gasValues',
      //           enableSorting: false,
      //           cell: (info) => {
      //             const gasValue: iSingleGasValue[] = info.getValue() as iSingleGasValue[]
      //             const { name } = gasValue[3] ?? { name: '' }
      //             return (
      //               <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
      //                 {name}
      //               </div>
      //             )
      //           },
      //         },
      //         {
      //           id: columnIDs.gasLevels[3],
      //           header: 'Gas value',
      //           accessorKey: 'gasValues',
      //           cell: (info) => {
      //             const gasValue: iSingleGasValue[] = info.getValue() as iSingleGasValue[]
      //             const { value, uom } = gasValue[3] ?? { value: '', uom: '' }
      //             return (
      //               <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
      //                 {`${value} ${uom}`}
      //               </div>
      //             )
      //           },
      //         },
      //       ],
      //     },

      //     {
      //       id: 'Gasdata5',
      //       header: 'Channel 5',
      //       meta: {
      //         headerClassName: 'justify-center',
      //       },
      //       columns: [
      //         {
      //           id: columnIDs.gasTypes[4],
      //           header: 'Gas type',
      //           accessorKey: 'gasValues',
      //           enableSorting: false,
      //           cell: (info) => {
      //             const gasValue: iSingleGasValue[] = info.getValue() as iSingleGasValue[]
      //             const { name } = gasValue[4] ?? { name: '' }
      //             return (
      //               <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
      //                 {name}
      //               </div>
      //             )
      //           },
      //         },
      //         {
      //           id: columnIDs.gasLevels[4],
      //           header: 'Gas value',
      //           accessorKey: 'gasValues',
      //           cell: (info) => {
      //             const gasValue: iSingleGasValue[] = info.getValue() as iSingleGasValue[]
      //             const { value, uom } = gasValue[4] ?? { value: '', uom: '' }
      //             return (
      //               <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
      //                 {`${value} ${uom}`}
      //               </div>
      //             )
      //           },
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
    [],
  )

  const resetFilterFormParams = () => {
    searchParams.delete(DeviceGasLogFilterParamLabels.dateRangeFrom)
    searchParams.delete(DeviceGasLogFilterParamLabels.dateRangeTo)
    searchParams.delete(DeviceGasLogFilterParamLabels.filterDateFrom)
    searchParams.delete(DeviceGasLogFilterParamLabels.filterDateTo)
    searchParams.delete(DeviceGasLogFilterParamLabels.channelNumber)
    searchParams.delete(DeviceGasLogFilterParamLabels.gasName)
    searchParams.delete(DeviceGasLogFilterParamLabels.gasValue)
    searchParams.delete(DeviceGasLogFilterParamLabels.gasUnit)
    setSearchParams(searchParams)
  }

  const handleFilterFormSubmit = useCallback(
    (formData: IFilterValues[]) => {
      formData.forEach((filterValue) => {
        const { columnId, value } = filterValue
        switch (columnId) {
          case columnIDs.channelNumber:
            if (value === '') {
              searchParams.delete(DeviceGasLogFilterParamLabels.channelNumber)
            } else {
              searchParams.set(DeviceGasLogFilterParamLabels.channelNumber, value as string)
            }
            break
          case columnIDs.gasName:
            if (value === '') {
              searchParams.delete(DeviceGasLogFilterParamLabels.gasName)
            } else {
              searchParams.set(DeviceGasLogFilterParamLabels.gasName, value as string)
            }
            break
          case columnIDs.gasValue:
            if (value === '') {
              searchParams.delete(DeviceGasLogFilterParamLabels.gasValue)
            } else {
              searchParams.set(DeviceGasLogFilterParamLabels.gasValue, value as string)
            }
            break
          case columnIDs.gasUnit:
            if (value === '') {
              searchParams.delete(DeviceGasLogFilterParamLabels.gasUnit)
            } else {
              searchParams.set(DeviceGasLogFilterParamLabels.gasUnit, value as string)
            }
            break
          case columnIDs.timeStamp:
            if (value === '') {
              searchParams.delete(DeviceGasLogFilterParamLabels.dateRangeFrom)
              searchParams.delete(DeviceGasLogFilterParamLabels.dateRangeTo)
              searchParams.delete(DeviceGasLogFilterParamLabels.filterDateFrom)
              searchParams.delete(DeviceGasLogFilterParamLabels.filterDateTo)
            } else {
              const dateRangeToBeFiltered = value as DateRange
              // const isFromBeforeOrSame =
              //   isBefore(new Date(dateRangeToBeFiltered.startDate), new Date(from)) ||
              //   isEqual(new Date(dateRangeToBeFiltered.startDate), new Date(from))

              // const isToAfterOrSame =
              //   isAfter(new Date(dateRangeToBeFiltered.endDate), new Date(to)) ||
              //   isEqual(new Date(dateRangeToBeFiltered.endDate), new Date(to))

              // const isDateRangeWider = isFromBeforeOrSame && isToAfterOrSame

              searchParams.set(
                DeviceGasLogFilterParamLabels.dateRangeFrom,
                new Date(dateRangeToBeFiltered.startDate).toISOString().split('T')[0],
              )

              searchParams.set(
                DeviceGasLogFilterParamLabels.dateRangeTo,
                new Date(dateRangeToBeFiltered.endDate).toISOString().split('T')[0],
              )

              // searchParams.set(
              //   DeviceGasLogFilterParamLabels.filterDateFrom,
              //   new Date(dateRangeToBeFiltered.startDate).toISOString().split('T')[0],
              // )

              // searchParams.set(
              //   DeviceGasLogFilterParamLabels.filterDateTo,
              //   new Date(dateRangeToBeFiltered.endDate).toISOString().split('T')[0],
              // )
            }
            break
          default:
            break
        }
      })

      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  const filterTableValues = useMemo(
    () =>
      [
        {
          columnId: columnIDs.timeStamp,
          value:
            isFromDate && isToDate
              ? {
                  startDate: new Date(filterDateFrom),
                  endDate: new Date(filterDateTo),
                }
              : '',
        },
        {
          columnId: columnIDs.channelNumber,
          value: channelNumberParam,
        },
        {
          columnId: columnIDs.gasName,
          value: gasNameParam,
        },
        // {
        //   columnId: columnIDs.gasValue,
        //   value: gasValueParam,
        // },
        {
          columnId: columnIDs.gasUnit,
          value: gasUnitParam,
        },
      ].filter((ele) => ele.value),
    [
      filterDateFrom,
      filterDateTo,
      channelNumberParam,
      gasNameParam,
      gasUnitParam,
      gasUoMList,
      gasLogsData,
    ],
  )

  const convertISelectOptionPropsToFormType = useMemo(() => {
    const channelNumberFiltered = filterTableValues.find(
      (filterValue) => filterValue.columnId === columnIDs.channelNumber,
    )?.value as string

    const gasNameFiltered = filterTableValues.find(
      (filterValue) => filterValue.columnId === columnIDs.gasName,
    )?.value as string

    const gasValueFiltered = filterTableValues.find(
      (filterValue) => filterValue.columnId === columnIDs.gasValue,
    )?.value as string

    const gasUnitFiltered = filterTableValues.find(
      (filterValue) => filterValue.columnId === columnIDs.gasUnit,
    )?.value as string

    const dateRangeFiltered = (filterTableValues.find(
      (filterValue) => filterValue.columnId === columnIDs.timeStamp,
    )?.value ?? {
      startDate: filterDateFrom,
      endDate: filterDateTo,
    }) as DateRange

    const r: DeviceGasLogsFilterFormType = {
      dateRangeSelector: dateRangeOptions.find(
        (option) => option.value === DateRangeSelectorTypes.Custom,
      ) ?? {
        label: '',
        value: '',
      },

      dateRange: {
        startDate: new Date(dateRangeFiltered?.startDate),
        endDate: new Date(dateRangeFiltered?.endDate),
      },

      channelNumber:
        channelNumberFiltered === ''
          ? selectAllOption
          : {
              label: channelNumberFiltered,
              value: channelNumberFiltered,
            },

      gasName:
        gasNameFiltered === ''
          ? selectAllOption
          : {
              label: gasNameFiltered,
              value: gasNameFiltered,
            },

      gasValue: gasValueFiltered,

      gasUnit:
        gasUnitFiltered === ''
          ? selectAllOption
          : {
              label: gasUnitFiltered,
              value: gasUnitFiltered,
            },
    }

    return r
  }, [filterTableValues, filterDateFrom, filterDateTo])

  const showFilter = () => {
    dispatch({
      type: DeviceDetailContextActions.ShowFilterForm,
      payload: {
        page: DeviceDetailPageTabs.GasLog,
        filterFormData: {
          onFilterSubmit: (formData: IFilterValues[]) => handleFilterFormSubmit(formData),
          onFilterReset: () => resetFilterFormParams(),
          selectedValues: convertISelectOptionPropsToFormType,
          channelNumbers: channelNumbersToFilter,
          gasNames: gasNamesToFilter,
          gasValues: [],
          gasUnits: gasUnitsToFilter,
        },
      },
    })
  }

  const hideFilterForm = () => {
    dispatch({ type: DeviceDetailContextActions.HideFilterForm })
  }

  // useEffect(() => {
  //   if (is2xlAndAbove) {
  //     showFilter()
  //   } else {
  //     hideFilterForm()
  //   }
  // }, [is2xlAndAbove])

  return (
    // Return the JSX that will be rendered
    <>
      <ExportData
        handleCancel={() => setShowExportDialog(false)}
        IsOpen={showExportDialog && isExportGasLogsDataSuccess}
        data={exportGasLogsData?.data ?? []}
        reportColumns={GasLogsReportColumns}
        widgetForReport={[]}
        reportTitle='Device gas levels'
        filterSummary={GasLogFilterFormat(
          filterTableValues,
          deviceDetails?.serialNumber || '',
          deviceDetails?.deviceType || '',
        )}
      />
      {/* <div className='flex'>
        <div className='flex-grow overflow-x-auto'> */}
      <TsTable<iSingleGasLogTabular>
        data={
          gasLogsData?.data.map((rows) => ({
            ...rows,
            gasValueUoM: `${rows?.gasValue} ${rows?.uom}`,
          })) ?? []
        }
        dataIsLoading={gasLogsDataLoading}
        columns={gasLogsTableColumns}
        showGlobalActionButton={false}
        globalActionButton={<NavigateBackButton />}
        showGlobalFilter={false}
        getRowCanExpand={() => false}
        onPrint={() => {}}
        onExport={() => {}}
        onShowFilter={() => showFilter()}
        resetFilter={() => resetFilterFormParams()}
        updateFilteredData={() => {}}
        renderFilterSummary={() => <> </>}
        filterValues={filterTableValues}
        minDisplayRows={10}
        setShowExportDialog={(val) => setShowExportDialog(val)}
        manualPagination
        defaultSortedColumKey={columnIDs.timeStamp}
        manualPageIndex={Number(pageIndexParam ?? DEFAULT_PAGE_INDEX)}
        manualPageSize={Number(pageSizeParam ?? DEFAULT_PAGE_SIZE)}
        manualTotalRecordCount={gasLogsData?.pagination?.totalRecords}
        onManualPageChange={(pageNum) => {
          searchParams.set(DeviceGasLogFilterParamLabels.pageNumber, pageNum.toString())
          setSearchParams(searchParams)
        }}
        onManualPageSizeChange={(pSize) => {
          searchParams.set(DeviceGasLogFilterParamLabels.pageSize, pSize.toString())
          setSearchParams(searchParams)
        }}
        renderTabs={() => <DeviceDetailsReportSelector />}
      />
      {/* </div> */}
      {/* <DeviceAssignmentHistoryFilterForm
          onFilterSubmit={filterAssignmentHistory}
          onFilterReset={() => {}}
          onFilterFormClose={() => {}}
          selectedValues={filterFormSelectedValues}
          deviceUserNames={deviceUserNamesInData}
          deviceUserUniqueIds={deviceUserUniqueIDsInData}
        /> */}
      {/* </div> */}
    </>
  )
}

export default withErrorBoundary(withPageTracking(DeviceGasLogs), {
  FallbackComponent: ErrorPage,
})

// const [tableColumns, setTableColumns] = React.useState<ColumnDef<iReportData>[]>(reportColumns)

// const columnHelper = createColumnHelper<iReportData>()
// useEffect(() => {
//   if (deviceDetails && reportData && reportData.length > 0) {
//     const maxChannels = MaxNumberOfChannels[deviceDetails.deviceType]
//     console.log('maxChannels', maxChannels)
//     if (reportColumns.length < 2) {
//       // const gasDataGroupColumn = columnHelper.group({
//       //   id: columnIDs.gasData,
//       //   header: 'Gas data',
//       //   columns: [],
//       // })

//       const accessorColumns = []
//       for (let channelCounter = 0; channelCounter < maxChannels; channelCounter += 1) {

//         const gasDataAccessorColumn = columnHelper.accessor('gasValues', {
//           id: columnIDs.gasTypes[channelCounter],
//           header: `Channel ${channelCounter + 1}`,
//           cell: (info) => {
//             const gasValue: iSingleGasValue[] = info.getValue() as iSingleGasValue[]
//             const { value, uom } = gasValue[channelCounter]
//             return (
//               <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
//                 {`${value} ${uom}`}
//               </div>
//             )
//           },
//         })

//         accessorColumns.push(gasDataAccessorColumn)
//       }

//       const gasDataGroupColumn = columnHelper.group({
//         id: columnIDs.gasData,
//         header: 'Gas data',
//         columns: accessorColumns,
//       })

//       reportColumns.push(gasDataGroupColumn)
//       setTableColumns(reportColumns)
//     }
//   }
// }, [deviceDetails, reportData])

// const [deviceLogUploadClientsInData, setDeviceLogUploadClientsInData] = React.useState<
//   IselectOptionProps[]
// >([])

// useEffect(() => {
//   if (deviceSyncHistory) {
//     const uploadTypes: IselectOptionProps[] = []
//     const uploadClients: IselectOptionProps[] = []

//     DeviceLogUploadTypes.forEach((type) => {
//       if (deviceSyncHistory.find((log) => log.uploadLogType === type.value)) {
//         uploadTypes.push({
//           label: type.label,
//           value: type.value,
//           isDisabled: false,
//         })
//       } else {
//         uploadTypes.push({
//           label: type.label,
//           value: type.value,
//           isDisabled: true,
//         })
//       }
//     })

//     uploadTypes.unshift(selectAllOption)

//     DeviceLogUploadClients.forEach((client) => {
//       if (deviceSyncHistory.find((log) => log.clientType === client.value)) {
//         uploadClients.push({
//           label: client.label,
//           value: client.value,
//           isDisabled: false,
//         })
//       } else {
//         uploadClients.push({
//           label: client.label,
//           value: client.value,
//           isDisabled: true,
//         })
//       }
//     })

//     uploadClients.unshift(selectAllOption)

//     setDeviceLogUploadTypesInData(uploadTypes)
//     setDeviceLogUploadClientsInData(uploadClients)
//   }
// }, [deviceSyncHistory])

// const gasDataGroupColumn = columnHelper.group({
//   id: columnIDs.gasData,
//   header: 'Gas data',
//   columns: [
//     columnHelper.accessor('gasValues', {
//       id: columnIDs.gasTypes[0],
//       header: 'Gas Type',
//       accessorFn: (data) => {
//         const {gasType} = data.gasValues[0]
//         return gasType
//       },
//       cell: (info) => {
//         const value: string = info.getValue() as string
//         return (
//           <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
//             {value}
//           </div>
//         )
//       },
//     }),
//   ],
// })

// reportColumns.push(gasDataGroupColumn)

// const filterAssignmentHistory = (formFilterValues: IFilterValues[]) => {
//   const filterValueAssignmentDateRange = formFilterValues.find(
//     (filterValue) => filterValue.columnId === columnIDs.deviceUserAssignedOn,
//   )?.value as DateRange

//   const isDateRangeNarrower =
//     (isAfter(
//       new Date(filterValueAssignmentDateRange.startDate),
//       new Date(assignmentHistoryFromToDates.startDate),
//     ) ||
//       isEqual(
//         new Date(filterValueAssignmentDateRange.startDate),
//         new Date(assignmentHistoryFromToDates.startDate),
//       )) &&
//     (isBefore(
//       new Date(assignmentHistoryFromToDates.endDate),
//       new Date(filterValueAssignmentDateRange.endDate),
//     ) ||
//       isEqual(
//         new Date(assignmentHistoryFromToDates.endDate),
//         new Date(filterValueAssignmentDateRange.endDate),
//       ))

//   if (isDateRangeNarrower) {
//     setFilterValues(formFilterValues)
//   } else {
//     const { startDate, endDate } = filterValueAssignmentDateRange

//     // navigate(`/devices/${deviceSerialNumber}/eventlog?from=${startDate}&to=${endDate}`)

//     setSearchParams({
//       from: new Date(startDate).toISOString().split('T')[0],
//       to: new Date(endDate).toISOString().split('T')[0],
//     })
//   }
// }

// const [filterFormSelectedValues, setFilterFormSelectedValues] =
//   React.useState<DeviceAssignmentHistoryFilterFormType>({
//     assignedDateSelector: dateRangeOptions.find(
//       (option) => option.value === DateRangeSelectorTypes.Custom,
//     ) ?? {
//       label: '',
//       value: '',
//     },

//     assignedDate: {
//       startDate: new Date(from),
//       endDate: new Date(to),
//     },

//     returnedDateSelector: dateRangeOptions.find(
//       (option) => option.value === DateRangeSelectorTypes.All,
//     ) ?? {
//       label: '',
//       value: '',
//     },

//     returnedDate: {
//       startDate: new Date(),
//       endDate: new Date(),
//     },

//     deviceUserName: {
//       label: '',
//       value: '',
//     },

//     deviceUserUniqueID: {
//       label: '',
//       value: '',
//     },
//   })
