import React, { useMemo } from 'react'
import { ColumnDef, Row, VisibilityState } from '@tanstack/react-table'
import { isValid } from 'date-fns'
import { FormattedDate } from 'react-intl'
import { IFilterValues } from 'forms/FormModelInterface'
import {
  ActiveInActiveFilter,
  AssignedUserFilterFn,
  DateRangeColumnFilterFn,
  DeviceChannelInfoGasTypeFilter,
} from 'forms/FormUtils'
import { DeviceChannelInfoDto } from 'data/DeviceListHook'
import TsTable from 'components/table/TsTable'
import { ExpandTrueIcon, ExpandFalseIcon, DeviceLogIcon } from 'assets/icons'
import Tooltip from 'components/atom/Tooltip'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { iDeviceListDataWithStats } from '../data/DevicePageData'
import { DevicePageColumnIds } from './DevicesPageUtils'
import AddDeviceButton from '../add/AddDeviceButton'
import DeviceConfigSubRow from './DeviceConfigSubRow'
import { DeviceStatus } from './DeviceTableComponents'

interface serviceFaultsTableProps {
  tableData?: iDeviceListDataWithStats[]
  isLoading: boolean
  filterValues: IFilterValues[]
  updateFilteredData: (data: iDeviceListDataWithStats[]) => void
  onShowFilter: () => void
  onFilterFormSubmit: (filterValues: IFilterValues[]) => void
  onFilterFormReset: () => void
  setShowExportDialog: (val: boolean) => void
}

function DevicePageTable(tableDataProps: serviceFaultsTableProps): JSX.Element {
  const {
    tableData,
    filterValues,
    isLoading,
    updateFilteredData,
    onShowFilter,
    onFilterFormReset,
    setShowExportDialog,
  } = tableDataProps

  const navigate = useNavigate()
  function navigateToDeviceDetail(serialNumber: string) {
    navigate(`/web/devices/${serialNumber}/eventlog`)
  }

  const tableColumns = useMemo<ColumnDef<iDeviceListDataWithStats>[]>(
    () => [
      {
        id: 'tool-bar',
        meta: {
          cellClassName: 'w-5 min-w-5 max-w-5',
          headerClassName: 'w-5 min-w-5 max-w-5',
          shouldDraggable: false,
          resizable: false,
          sortable: false,
        },
        cell: ({ row }) => (
          <div>
            {row.getCanExpand() ? (
              <button
                type='button'
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  className: 'mr-2',
                }}
              >
                {row.getIsExpanded() ? <ExpandTrueIcon /> : <ExpandFalseIcon />}
              </button>
            ) : (
              <div className='w-4' />
            )}
          </div>
        ),
      },
      {
        id: DevicePageColumnIds.serialNumber,
        header: 'Serial  #',
        accessorKey: 'serialNumber',
        cell: ({ row }) => {
          const value: string = row?.original?.serialNumber ?? ''

          return (
            <div
              className='w-full h-full content-center cursor-pointer '
              title='Double click to view device details'
              onDoubleClick={() => navigateToDeviceDetail(row?.original?.serialNumber)}
            >
              <div className='flex-grow  font-bold text-2xs leading-5  text-ellipsis  '>
                {value}
              </div>
            </div>
          )
        },
        meta: {},
        filterFn: 'includesString',
      },
      {
        id: DevicePageColumnIds.deviceType,
        header: 'Device',
        accessorKey: 'deviceType',
        cell: (info) => {
          const value: string = info.getValue() as string
          // const deviceTypeText = GetProductNameText(value as PortableTypes)
          return (
            <div
              className='w-full h-full content-center cursor-pointer'
              title='Double click to view device details'
              onDoubleClick={() => navigateToDeviceDetail(info.row?.original?.serialNumber)}
            >
              <div className=' py-3 w-20 text-2xs font-normal leading-5  text-ellipsis '>
                {value}
              </div>
            </div>
          )
        },
        meta: {},
        filterFn: 'includesString',
      },
      {
        id: DevicePageColumnIds.assetNumber,
        header: 'Asset #',
        accessorKey: 'customerAssetNumber',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <div
              className='w-full h-full content-center cursor-pointer'
              title='Double click to view device details'
              onDoubleClick={() => navigateToDeviceDetail(info.row?.original?.serialNumber)}
            >
              <div className='font-poppins text-2xs leading-4  text-ellipsis font-normal  text-c-dark-blue-1 '>
                {value}
              </div>
            </div>
          )
        },
        meta: {},
        filterFn: 'includesString',
      },
      {
        id: DevicePageColumnIds.businessUnit,
        header: 'Business Unit',
        accessorFn: (row) => `${row?.businessUnit?.name}`,
        cell: (info) => {
          const businessUnitName = info.getValue() as string

          return businessUnitName ? (
            <div
              className='w-full h-full content-center cursor-pointer'
              title='Double click to view device details'
              onDoubleClick={() => navigateToDeviceDetail(info.row?.original?.serialNumber)}
            >
              <Tooltip
                tooltipText={businessUnitName}
                toolTipClass='tooltip '
                showOnlyWhenTextIsTruncate
                id={`ellipsis-text-${businessUnitName}`}
                className='font-normal text-2xs leading-5  text-ellipsis '
              >
                {businessUnitName}
              </Tooltip>
            </div>
          ) : (
            <div className='w-40 mr-2 h-5 bg-c-light-blue-1 rounded-lg animate-pulse' />
          )
        },
        meta: {},
        filterFn: 'includesString',
      },

      {
        id: DevicePageColumnIds.deviceStatus,
        header: 'Status',
        accessorKey: 'status',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <div
              className='w-full h-full content-center cursor-pointer'
              title='Double click to view device details'
              onDoubleClick={() => navigateToDeviceDetail(info.row?.original?.serialNumber)}
            >
              <DeviceStatus data={value} />
            </div>
          )
        },
        meta: {},
        filterFn: ActiveInActiveFilter,
      },
      {
        id: DevicePageColumnIds.lastSync,
        header: 'Synced',
        accessorKey: 'lastUploadDate',
        cell: (info) => {
          const value = info.getValue() as string
          const date = new Date(value)
          const isLastSyncDateValid = isValid(date)
          return (
            <div
              className='w-full h-full content-center cursor-pointer'
              title='Double click to view device details'
              onDoubleClick={() => navigateToDeviceDetail(info.row?.original?.serialNumber)}
            >
              <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1   '>
                {isLastSyncDateValid && <FormattedDate value={date} />}
              </div>
            </div>
          )
        },
        meta: {},
        filterFn: DateRangeColumnFilterFn<iDeviceListDataWithStats>,
      },
      {
        id: DevicePageColumnIds.lastSwitchOn,
        header: 'Last on',
        accessorKey: 'lastSwitchOnDate',
        cell: (info) => {
          const value = info.getValue() as string
          const date = new Date(value)
          const isLastSWOnDateValid = isValid(date)
          return (
            <div
              className='w-full h-full content-center cursor-pointer'
              title='Double click to view device details'
              onDoubleClick={() => navigateToDeviceDetail(info.row?.original?.serialNumber)}
            >
              <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1'>
                {isLastSWOnDateValid && <FormattedDate value={date} />}
              </div>
            </div>
          )
        },
        meta: {},
        filterFn: DateRangeColumnFilterFn<iDeviceListDataWithStats>,
      },
      {
        id: DevicePageColumnIds.deviceUser,
        header: 'Assigned',
        accessorFn: (row) => `${row.deviceUser ? row.deviceUser?.fullName ?? '' : ''}`,
        cell: (info) => {
          const value: string = info.getValue() as string

          return (
            <div
              className='w-full h-full content-center cursor-pointer'
              title='Double click to view device details'
              onDoubleClick={() => navigateToDeviceDetail(info.row?.original?.serialNumber)}
            >
              <Tooltip
                showOnlyWhenTextIsTruncate
                id={`tooltip-${value}-assigned-user`}
                tooltipText={value}
                toolTipClass='tooltip'
                className={`  text-2xs leading-4 font-normal text-c-dark-blue-1  text-ellipsis  `}
                title='Double click to view device details'
                onDoubleClick={() => navigateToDeviceDetail(info.row?.original?.serialNumber)}
              >
                {value}
              </Tooltip>
            </div>
          )
        },
        meta: {},
        // filterFn: 'includesString',
        filterFn: AssignedUserFilterFn<iDeviceListDataWithStats>,
      },
      {
        id: DevicePageColumnIds.gasType,
        header: 'Gas Type',
        accessorKey: 'channels',
        cell: (info) => {
          const value: DeviceChannelInfoDto[] = info.getValue() as DeviceChannelInfoDto[]
          return (
            <div
              className='w-full h-full content-center cursor-pointer'
              title='Double click to view device details'
              onDoubleClick={() => navigateToDeviceDetail(info.row?.original?.serialNumber)}
            >
              <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1  '>
                {value?.map((item) => (
                  <div key={item.channelNumber}>{item.channelName}</div>
                ))}
              </div>
            </div>
          )
        },
        meta: {
          forcedHidden: true,
        },
        filterFn: DeviceChannelInfoGasTypeFilter<iDeviceListDataWithStats>,
      },
      {
        id: '999',
        cell: (rowProps) => {
          const rowData = rowProps.row.original
          return (
            <Link
              to={`/web/devices/${rowData?.serialNumber}/eventlog`}
              className='group/device-link rounded-full p-1.5 ml-4   hover:cursor-pointer hover:drop-shadow hover:bg-c-light-blue-3'
              //   state={{ deviceData: rowData}}
            >
              {/* <div className='group-hover/device-link:font-medium  text-xs flex flex-row justify-end items-center group-hover/device-link:text-white'> */}
              {/* <RowActions rowData={rowProps.row.original} /> */}
              {/* View */}
              {/* </div> */}
              {/* <NewTabIcon className='w-5 h-5  fill-c-dark-blue-1 stroke-transparent transition-transform duration-75 ease-in ' /> */}
              <DeviceLogIcon className='w-5 h-5' />
              {/* <DropDownIcon className=' group-hover/device-link:stroke-white  stroke-black fill-transparent transition-transform duration-75 ease-in -rotate-90' /> */}
            </Link>
          )
        },
        meta: {
          cellClassName: 'w-10 min-w-10 max-w-10 overflow-visible',
          headerClassName: 'w-10 min-w-10 max-w-10 overflow-visible',
          shouldDraggable: false,
          resizable: false,
          sortable: false,
        },
      },
    ],
    [],
  )

  const renderFilterSummaryComponent = () => <> </>
  const location = useLocation()
  const renderRowSubComponent = ({ row }: { row: Row<iDeviceListDataWithStats> }) => {
    const deviceId = row.original.id ?? ''

    return <DeviceConfigSubRow deviceId={deviceId} />
  }

  const columnVisibility: VisibilityState = {
    [DevicePageColumnIds.gasType]: false,
  }

  return (
    <TsTable<iDeviceListDataWithStats>
      columns={tableColumns}
      data={tableData ?? []}
      dataIsLoading={isLoading}
      showGlobalFilter
      onExport={() => {}}
      onPrint={() => {}}
      getRowCanExpand={() => true}
      renderSubComponent={renderRowSubComponent}
      updateFilteredData={(data) => updateFilteredData(data)}
      renderFilterSummary={renderFilterSummaryComponent}
      resetFilter={() => onFilterFormReset()}
      onShowFilter={() => onShowFilter()}
      filterValues={filterValues}
      showGlobalActionButton
      defaultSortedColumKey={DevicePageColumnIds.serialNumber}
      globalActionButton={<AddDeviceButton />}
      setShowExportDialog={(e) => setShowExportDialog(e)}
      columnVisibility={columnVisibility}

      // setFilterValues={setFilterValues}
      // dateRangeSelectorType={DateRangeSelectorTypes.DateRange}
      // dateRangeOptions={dateRangeOptions}
      // dateRangeFilterFn={DateRangeColumnFilterFn<iFaultData>}
    />
  )
}

export default withErrorBoundary(DevicePageTable, {
  FallbackComponent: ErrorPage,
})
