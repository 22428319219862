import _ from 'lodash'
import { add, format, startOfMonth, endOfMonth, isEqual, isAfter, isBefore } from 'date-fns'
import { PortableTypes, DeviceCalDueTypes } from 'utils/CommonEnums'

import { DateRange, IselectOptionProps } from 'forms/FormModelInterface'
import { selectAllOption } from 'forms/FormUtils'
import {
  iDeviceCalDueByDaysCount,
  iDeviceCalDueByMonthCount,
} from 'pages/devices/data/iDevicesList'
import { iDevice } from 'data/DeviceListHook'
import { DeviceCalDueTypeTexts } from 'utils/Constants'
import { GetProductNameText } from 'utils/CommonUtils'
import { dayDateToDate, returnStaticDate } from 'components/modules/Calender/CalenderUtils'
import { CalibrationPageColumnIds } from '../view/CalibrationsPageUtils'

export const PrepareDeviceTypeOptionsForSelect = (deviceData: iDevice[]): IselectOptionProps[] => {
  // temporary remove gasman devices for test
  // const deviceDataWithoutGasman = removeGasmanDevices(deviceData);

  // Get all enums in a string array
  const portableTypes: IselectOptionProps[] = []

  Object.keys(PortableTypes).forEach((portableType) => {
    const isDisabled = !deviceData.some((device) => device?.deviceType?.toString() === portableType)
    const deviceTypeText = GetProductNameText(portableType as PortableTypes)
    portableTypes.push({
      value: deviceTypeText,
      label: deviceTypeText,
      // isDisabled,
    })
  })

  portableTypes.unshift(selectAllOption)

  return portableTypes
}

export const groupDeviceDataByCalDueDays = (deviceData: iDevice[]): iDeviceCalDueByDaysCount[] => {
  const deviceCalDueByPeriodCount: iDeviceCalDueByDaysCount[] = []

  const devicesGroupedByCalDuePeriod = _.groupBy(deviceData, 'calDueStatus')

  const deviceduein7d = devicesGroupedByCalDuePeriod[DeviceCalDueTypes.DueIn7Days]?.length || 0
  let deviceduein30d = devicesGroupedByCalDuePeriod[DeviceCalDueTypes.DueIn30Days]?.length || 0
  deviceduein30d += deviceduein7d

  let deviceduein60d = devicesGroupedByCalDuePeriod[DeviceCalDueTypes.DueIn60Days]?.length || 0
  deviceduein60d += deviceduein30d

  let deviceduein90d = devicesGroupedByCalDuePeriod[DeviceCalDueTypes.DueIn90Days]?.length || 0
  deviceduein90d += deviceduein60d

  deviceCalDueByPeriodCount.push({
    calDueParam: DeviceCalDueTypes.Overdue,
    count: devicesGroupedByCalDuePeriod[DeviceCalDueTypes.Overdue]?.length || 0,
    columnId: CalibrationPageColumnIds.calDue,
  })
  deviceCalDueByPeriodCount.push({
    calDueParam: DeviceCalDueTypes.DueIn7Days,
    count: deviceduein7d || 0,
    columnId: CalibrationPageColumnIds.calDue,
  })
  deviceCalDueByPeriodCount.push({
    calDueParam: DeviceCalDueTypes.DueIn30Days,
    count: deviceduein30d || 0,
    columnId: CalibrationPageColumnIds.calDue,
  })
  deviceCalDueByPeriodCount.push({
    calDueParam: DeviceCalDueTypes.DueIn60Days,
    count: deviceduein60d || 0,
    columnId: CalibrationPageColumnIds.calDue,
  })
  deviceCalDueByPeriodCount.push({
    calDueParam: DeviceCalDueTypes.DueIn90Days,
    count: deviceduein90d || 0,
    columnId: CalibrationPageColumnIds.calDue,
  })
  deviceCalDueByPeriodCount.push({
    calDueParam: DeviceCalDueTypes.DueOver90Days,
    count: devicesGroupedByCalDuePeriod[DeviceCalDueTypes.DueOver90Days]?.length || 0,
    columnId: CalibrationPageColumnIds.calDue,
  })
  deviceCalDueByPeriodCount.push({
    calDueParam: DeviceCalDueTypes.NotAvailable,
    count: devicesGroupedByCalDuePeriod[DeviceCalDueTypes.NotAvailable]?.length || 0,
    columnId: CalibrationPageColumnIds.calDue,
  })
  return deviceCalDueByPeriodCount
}

export const constructNextXMonths = (NumberOfMonths = 6): DateRange[] => {
  const arr = Array.from({ length: NumberOfMonths }, (_, i) => i)
  const NextXMonthsDateRange: DateRange[] = []
  arr.forEach((month) => {
    NextXMonthsDateRange.push({
      startDate: format(startOfMonth(add(new Date(), { months: month })), 'yyyy-MM-dd'),
      endDate: format(endOfMonth(add(new Date(), { months: month })), 'yyyy-MM-dd'),
    })
  })

  NextXMonthsDateRange.unshift({
    startDate: format(startOfMonth(new Date('1970-01-01')), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
    isCustom: true,
    customValue: DeviceCalDueTypes.Overdue,
  })
  // const NextXMonths = arr.map((month) => getMonth(add(new Date(), { months: month })))
  // NextXMonths.forEach((month) => {
  //   NextXMonthsDateRange.push({
  //     startDate: startOfMonth(new Date(month)),
  //     endDate: endOfMonth(new Date(month)),
  //   })
  // })

  return NextXMonthsDateRange
}

export const getDeviceCountDueInNextXMonths = (
  deviceData: iDevice[],
  NumberOfMonths = 6,
): iDeviceCalDueByMonthCount[] => {
  const next6MonthsStartEndDates = constructNextXMonths(NumberOfMonths)

  const deviceCalDueByMonthCount: iDeviceCalDueByMonthCount[] = []

  const getCountForMonth = (startDate: string, endDate: string) =>
    deviceData.filter((device) => {
      const deviceCalDueDate = device.calibrationDueDate
        ? dayDateToDate(device.calibrationDueDate.split('T')[0])
        : null

      return deviceCalDueDate
        ? (isBefore(deviceCalDueDate, dayDateToDate(endDate)) ||
            isEqual(deviceCalDueDate, dayDateToDate(endDate))) &&
            (isAfter(deviceCalDueDate, dayDateToDate(startDate)) ||
              isEqual(deviceCalDueDate, dayDateToDate(startDate)))
        : false
    }).length

  deviceCalDueByMonthCount.push({
    calDueMonth:
      DeviceCalDueTypeTexts.find((calDueType) => calDueType.value === DeviceCalDueTypes.Overdue)
        ?.value || 'Overdue',
    count: deviceData.filter((device) => {
      const deviceCalDueDate = device.calibrationDueDate
        ? dayDateToDate(device.calibrationDueDate?.split('T')?.[0])
        : null
      return deviceCalDueDate
        ? isBefore(deviceCalDueDate, dayDateToDate(returnStaticDate(new Date()))) ||
            isEqual(deviceCalDueDate, dayDateToDate(returnStaticDate(new Date())))
        : false
    }).length,
  })

  for (let i = 0; i < NumberOfMonths; i += 1) {
    deviceCalDueByMonthCount.push({
      calDueMonth: format(add(new Date(), { months: i }), 'MMM'),
      count: getCountForMonth(
        next6MonthsStartEndDates[i + 1].startDate as string,
        next6MonthsStartEndDates[i + 1].endDate as string,
      ),
    })
  }

  return deviceCalDueByMonthCount
}

// deviceCalDueByMonthCount.push({
//   calDueMonth: format(add(new Date(), { months: 1 }), 'MMM'),
//   count: deviceData.filter((device) => {
//     const deviceCalDueDate = device.calibrationDueDate
//       ? new Date(device.calibrationDueDate)
//       : null
//     return deviceCalDueDate
//       ? differenceInCalendarMonths(deviceCalDueDate, new Date()) <= 2 &&
//           differenceInCalendarMonths(deviceCalDueDate, new Date()) > 1
//       : false
//   }).length,
// })

// deviceCalDueByMonthCount.push({
//   calDueMonth: format(add(new Date(), { months: 2 }), 'MMM'),
//   count: deviceData.filter((device) => {
//     const deviceCalDueDate = device.calibrationDueDate
//       ? new Date(device.calibrationDueDate)
//       : null
//     return deviceCalDueDate
//       ? differenceInCalendarMonths(deviceCalDueDate, new Date()) <= 3 &&
//           differenceInCalendarMonths(deviceCalDueDate, new Date()) > 2
//       : false
//   }).length,
// })

// deviceCalDueByMonthCount.push({
//   calDueMonth: format(add(new Date(), { months: 3 }), 'MMM'),
//   count: deviceData.filter((device) => {
//     const deviceCalDueDate = device.calibrationDueDate
//       ? new Date(device.calibrationDueDate)
//       : null
//     return deviceCalDueDate
//       ? differenceInCalendarMonths(deviceCalDueDate, new Date()) <= 4 &&
//           differenceInCalendarMonths(deviceCalDueDate, new Date()) > 3
//       : false
//   }).length,
// })

// deviceCalDueByMonthCount.push({
//   calDueMonth: format(add(new Date(), { months: 4 }), 'MMM'),
//   count: deviceData.filter((device) => {
//     const deviceCalDueDate = device.calibrationDueDate
//       ? new Date(device.calibrationDueDate)
//       : null
//     return deviceCalDueDate
//       ? differenceInCalendarMonths(deviceCalDueDate, new Date()) <= 5 &&
//           differenceInCalendarMonths(deviceCalDueDate, new Date()) > 4
//       : false
//   }).length,
// })

// deviceCalDueByMonthCount.push({
//   calDueMonth: format(add(new Date(), { months: 5 }), 'MMM'),
//   count: deviceData.filter((device) => {
//     const deviceCalDueDate = device.calibrationDueDate
//       ? new Date(device.calibrationDueDate)
//       : null
//     return deviceCalDueDate
//       ? differenceInCalendarMonths(deviceCalDueDate, new Date()) <= 6 &&
//           differenceInCalendarMonths(deviceCalDueDate, new Date()) > 5
//       : false
//   }).length,
// })
