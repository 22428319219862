/* eslint-disable import/no-duplicates */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { isAfter, isBefore, isEqual, fromUnixTime, sub } from 'date-fns'
import { useDeviceDetails } from 'pages/deviceDetails/rootPage/view/DeviceDetailsRoot'
import { ColumnDef } from '@tanstack/react-table'
import { FormattedDate, FormattedTime, useIntl } from 'react-intl'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
import { DateRangeColumnFilterUnixTimeFn, dateRangeOptions } from 'forms/FormUtils'
import TsTable from 'components/table/TsTable'
import { DateRangeSelectorTypes, DeviceDetailPageTabs, PortableTypes } from 'utils/CommonEnums'
import {
  DeviceDetailContextActions,
  DeviceDetailsContext,
} from 'pages/deviceDetails/rootPage/view/DeviceDetailsContext'
import ExportData from 'exportReports/ExportData'
import Tooltip from 'components/atom/Tooltip'
import { withPageTracking } from 'utils/AppInsightConfig'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { returnDateWithoutTimeOffset } from 'utils/Constants'
import DeviceDetailsReportSelector from 'pages/deviceDetails/rootPage/view/DeviceDetailsReportSelector'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { iDeviceEventLog } from '../data/interface'
import { useDeviceEventLogsData } from '../data/DeviceEventLogsData'
import { NavigateBackButton } from '../../../rootPage/view/NavigateBackButton'
import { prepareEventLogsFormData } from '../data/DeviceDetailsDataUtils'
import { DeviceEventLogFilterParamLabels } from './DeviceEventLogPageUtils'
import { EventLogsReportColumns } from '../export/EventLogsReportFormat'
import EventLogsFilterFormat from '../export/EventLogsFilterFormat'

export type DeviceEventLogFilterFormType = {
  eventDateSelector: IselectOptionProps
  eventDate: DateRange
  eventDescription: IselectOptionProps
  eventDeviceUser: IselectOptionProps
}

export const columnIDs = {
  eventDate: 'timeStamp',
  eventTime: 'eventTime',
  eventDescription: 'eventDescription',
  eventDeviceUser: 'eventDeviceUser',
}

function DeviceEventLogs(): JSX.Element {
  const deviceDetailsContext = useContext(DeviceDetailsContext)
  const { dispatch } = deviceDetailsContext

  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`
  const intl = useIntl()

  // const [filterValues, setFilterValues] = React.useState<IFilterValues[]>([])
  const [showFilterForm, setShowFilterForm] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()

  const params = useParams<{ deviceSerialNumber: string }>()
  const { deviceSerialNumber } = params

  useEffect(() => {
    document.title = `Event Logs ${deviceSerialNumber}`
  }, [])

  /**
   * Check if the date range is set in the URL
   * @param {string} isFromDate - The from date in the URL
   * @param {string} isToDate - The to date in the URL
   * @param {string} filterLogsDateTo - The to date in the URL
   * @param {string} filterLogsDateFrom - The from date in the URL
   */
  const isFromDate =
    searchParams.get(DeviceEventLogFilterParamLabels.dateRangeFrom) ||
    searchParams.get(DeviceEventLogFilterParamLabels.filterDateFrom)
  const isToDate =
    searchParams.get(DeviceEventLogFilterParamLabels.dateRangeTo) ||
    searchParams.get(DeviceEventLogFilterParamLabels.filterDateTo)

  const filterLogsDateTo = returnDateWithoutTimeOffset(isToDate || new Date())
  const filterLogsDateFrom = returnDateWithoutTimeOffset(
    isFromDate || sub(isToDate ? new Date(isToDate) : new Date(), { months: 6 }),
  )

  const eventDescriptionParam =
    searchParams.get(DeviceEventLogFilterParamLabels.eventDescription) ?? ''
  const deviceUserParam = searchParams.get(DeviceEventLogFilterParamLabels.deviceUser) ?? ''
  const { deviceDetails } = useDeviceDetails()
  const { id: deviceId } = deviceDetails ?? { deviceId: '' }
  const [eventLogsTranslated, setEventLogsTranslated] = useState<iDeviceEventLog[]>([])
  const [showExportDialog, setShowExportDialog] = React.useState(false)
  const { genericEventHandler } = useGenericEventHandler()
  const {
    data: deviceEventLogs,
    isLoading: isLoadingDeviceEventLogs,
    isError: isErrorDeviceEventLogs,
    error: errorDeviceEventLogs,
  } = useDeviceEventLogsData(
    deviceId as string,
    filterLogsDateFrom.toISOString().split('T')[0],
    filterLogsDateTo.toISOString().split('T')[0],
    redirectPageURL,
    true,
  )

  useEffect(() => {
    if (isErrorDeviceEventLogs) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        message: errorDeviceEventLogs?.message || 'Error getting device event logs',
        error: errorDeviceEventLogs,
        extraData: {
          component: 'DeviceEventLogs',
          action: 'get device event logs',
        },
      })
    }
  }, [isErrorDeviceEventLogs, errorDeviceEventLogs])
  // const [is2xlAndAbove, setIs2xlAndAbove] = useState(false)

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIs2xlAndAbove(window.innerWidth >= ScreenWidths.xxl)
  //   }

  //   handleResize() // Initial check

  //   window.addEventListener('resize', handleResize)

  //   return () => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // }, [])

  useEffect(() => {
    if (deviceEventLogs && deviceEventLogs?.length > 0) {
      try {
        // const filteredEventLogs = deviceEventLogs?.filter((eventLog) => eventLog.eventId === 12)

        const translatedEventLogs = deviceEventLogs?.map((eventLog) => {
          const { eventDescription } = eventLog
          const translationKey = eventDescription as string
          if (!eventLog.eventLogValues) {
            const translatedDescription = intl.formatMessage({ id: translationKey })
            return {
              ...eventLog,
              eventDescription: translatedDescription,
            }
          }

          const translationValues: Record<string, string> = JSON.parse(eventLog.eventLogValues)
          if (
            (eventLog.eventId === 12 && deviceDetails?.deviceType === PortableTypes.Gasman) ||
            (eventLog.eventId === 22 && deviceDetails?.deviceType === PortableTypes.T3)
          ) {
            const date = new Date(translationValues.DataWord)
            if (date) {
              const translatedValue = intl.formatMessage(
                { id: translationKey },
                {
                  DataWord: eventLog.eventData * 1000,
                },
              )
              return {
                ...eventLog,
                eventDescription: translatedValue,
              }
            }
          }

          const translatedValue = intl.formatMessage({ id: translationKey }, translationValues)
          return {
            ...eventLog,
            eventDescription: translatedValue,
          }
        })
        setEventLogsTranslated(translatedEventLogs)
      } catch (error) {
        console.error(error)
      }
    }
  }, [deviceEventLogs])

  const [filterFormDescriptionValues, setFilterFormDescriptionValues] = React.useState<
    IselectOptionProps[]
  >([])

  const [filterFormDeviceUserValues, setFilterFormDeviceUserValues] = React.useState<
    IselectOptionProps[]
  >([])

  useEffect(() => {
    if (eventLogsTranslated) {
      const { eventDescriptions, eventDeviceUsers } = prepareEventLogsFormData(eventLogsTranslated)

      setFilterFormDescriptionValues(eventDescriptions)
      setFilterFormDeviceUserValues(eventDeviceUsers)
    }
  }, [eventLogsTranslated])

  const deviceEventLogTableColumns = useMemo<ColumnDef<iDeviceEventLog>[]>(
    () => [
      {
        id: columnIDs.eventDate,
        header: 'Date and time',
        accessorKey: 'timeStamp',
        cell: (info) => {
          const value: number = info.getValue() as number
          const date = fromUnixTime(value)
          return (
            <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
              <span className='mr-2'>
                <FormattedDate value={date} />
              </span>
              <span>
                <FormattedTime value={date} />
              </span>
            </div>
          )
        },
        meta: {},
        filterFn: DateRangeColumnFilterUnixTimeFn<iDeviceEventLog>,
      },
      {
        id: columnIDs.eventDescription,
        header: 'Description',
        accessorKey: 'eventDescription',
        accessorFn: (row) => {
          const { eventDescription } = row
          return eventDescription
        },
        cell: (info) => {
          const value = info.getValue() as string
          return (
            <Tooltip
              id={`eventlog-description-${value}`}
              showOnlyWhenTextIsTruncate
              tooltipText={value}
              toolTipClass='tooltip'
              className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 text-ellipsis  '
            >
              {value}
            </Tooltip>
          )
        },
        meta: {},
        // cell: (info) => {
        //   const translationKey = info.getValue() as string
        //   const translationValues = JSON.parse(info.row.original.eventLogValues)
        //   const { eventDescription } = info.row.original
        //   if (eventDescription === 'Eventlog.Gasman.New-Time-Set-On-Instrument-[DataWord]') {
        //     const { DataWord } = translationValues
        //     const date = new Date(DataWord)
        //     const isValidDate = isValid(date)
        //     if (isValidDate) {
        //       translationValues.intldatetime = <FormattedDate value={date} />
        //     }
        //   }

        //   return (
        //     <div>
        //       <FormattedMessage id={translationKey} values={translationValues} />
        //     </div>
        //   )
        // },
        filterFn: 'includesString',
        // filterFn: (row, _columnId, filterValue) => {
        //   // For client side filtering with the translations added, this
        //   // code again translates the row data and then filters it.
        //   // This is not ideal. Tanstack table should give the real row data, not only the original data
        //   // The original data is the data that is fetched from the server, and the translated data is the data that is shown in the table
        //   // The translated data is the data that should be filtered, not the original data

        //   const { eventDescription } = row.original
        //   const translationKey = eventDescription as string
        //   const translationValues: Record<string, string> = JSON.parse(row.original.eventLogValues)
        //   const transLatedValue = intl.formatMessage({ id: translationKey }, translationValues)
        //   return transLatedValue.includes(filterValue as string)
        // },
      },
      {
        id: columnIDs.eventDeviceUser,
        header: 'Assigned to',
        accessorKey: 'deviceUserName',
        cell: (info) => {
          const value = info.getValue() as string
          return (
            <Tooltip
              id={`eventlog-device-user-${value}`}
              showOnlyWhenTextIsTruncate
              tooltipText={value}
              toolTipClass='tooltip'
              className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1  text-ellipsis '
            >
              {value}
            </Tooltip>
          )
        },
        meta: {},
      },
    ],
    [],
  )

  const resetFilterFormParams = () => {
    searchParams.delete(DeviceEventLogFilterParamLabels.dateRangeFrom)
    searchParams.delete(DeviceEventLogFilterParamLabels.dateRangeTo)
    searchParams.delete(DeviceEventLogFilterParamLabels.filterDateFrom)
    searchParams.delete(DeviceEventLogFilterParamLabels.filterDateTo)
    searchParams.delete(DeviceEventLogFilterParamLabels.deviceUser)
    searchParams.delete(DeviceEventLogFilterParamLabels.eventDescription)
    setSearchParams(searchParams)
  }

  const handleFilterFormSubmit = useCallback(
    (formData: IFilterValues[]) => {
      formData.forEach((filter) => {
        if (filter.columnId === columnIDs.eventDescription && filter?.value !== null) {
          if (filter.value === '') {
            searchParams.delete(DeviceEventLogFilterParamLabels.eventDescription)
          } else {
            searchParams.set(
              DeviceEventLogFilterParamLabels.eventDescription,
              filter.value as string,
            )
          }
        }

        if (filter.columnId === columnIDs.eventDeviceUser) {
          if (filter.value === '') {
            searchParams.delete(DeviceEventLogFilterParamLabels.deviceUser)
          } else {
            searchParams.set(DeviceEventLogFilterParamLabels.deviceUser, filter.value as string)
          }
        }

        if (filter.columnId === columnIDs.eventDate) {
          if (filter.value === '') {
            searchParams.delete(DeviceEventLogFilterParamLabels.dateRangeFrom)
            searchParams.delete(DeviceEventLogFilterParamLabels.dateRangeTo)
            searchParams.delete(DeviceEventLogFilterParamLabels.filterDateFrom)
            searchParams.delete(DeviceEventLogFilterParamLabels.filterDateTo)
          } else {
            const dateRangeToBeFiltered = filter?.value as DateRange
            // Calculate if DateRange is wider based on below code
            const isFromBeforeOrSame =
              isBefore(new Date(dateRangeToBeFiltered.startDate), new Date(isFromDate as string)) ||
              isEqual(new Date(dateRangeToBeFiltered.startDate), new Date(isFromDate as string))

            const isToAfterOrSame =
              isAfter(new Date(dateRangeToBeFiltered.endDate), new Date(isToDate as string)) ||
              isEqual(new Date(dateRangeToBeFiltered.endDate), new Date(isToDate as string))

            const isDateRangeWider = isFromBeforeOrSame && isToAfterOrSame

            if (isDateRangeWider) {
              searchParams.set(
                DeviceEventLogFilterParamLabels.dateRangeFrom,
                new Date(dateRangeToBeFiltered.startDate).toISOString().split('T')[0],
              )

              searchParams.set(
                DeviceEventLogFilterParamLabels.dateRangeTo,
                new Date(dateRangeToBeFiltered.endDate).toISOString().split('T')[0],
              )
            }

            searchParams.set(
              DeviceEventLogFilterParamLabels.filterDateFrom,
              new Date(dateRangeToBeFiltered.startDate).toISOString().split('T')[0],
            )

            searchParams.set(
              DeviceEventLogFilterParamLabels.filterDateTo,
              new Date(dateRangeToBeFiltered.endDate).toISOString().split('T')[0],
            )
          }
        }
      })
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  const filterTableValues = useMemo(
    () => [
      {
        columnId: columnIDs.eventDescription,
        value: eventDescriptionParam ?? '',
      },
      {
        columnId: columnIDs.eventDeviceUser,
        value: deviceUserParam ?? '',
      },
      {
        columnId: columnIDs.eventDate,
        value:
          isFromDate && isToDate
            ? { startDate: new Date(isFromDate), endDate: new Date(isToDate) }
            : '',
      },
    ],
    [
      eventDescriptionParam,
      deviceUserParam,
      filterLogsDateFrom,
      filterLogsDateTo,
      isFromDate,
      isToDate,
    ],
  )

  const convertISelectOptionPropsToFormType = useMemo(() => {
    const eventDescriptionFiltered =
      filterTableValues.find((filter) => filter.columnId === columnIDs.eventDescription)?.value ??
      ''

    const dateRangeFiltered = (filterTableValues.find(
      (filter) => filter.columnId === columnIDs.eventDate,
    )?.value || {
      startDate: filterLogsDateFrom,
      endDate: filterLogsDateTo,
    }) as DateRange

    const deviceUserFiltered =
      filterTableValues.find((filter) => filter.columnId === columnIDs.eventDeviceUser)?.value ?? ''

    const r: DeviceEventLogFilterFormType = {
      eventDateSelector: dateRangeOptions.find(
        (option) => option.value === DateRangeSelectorTypes.Custom,
      ) ?? {
        label: '',
        value: '',
      },

      eventDate: {
        startDate: new Date(dateRangeFiltered.startDate),
        endDate: new Date(dateRangeFiltered.endDate),
      },

      eventDescription: {
        value: eventDescriptionFiltered,
        label: eventDescriptionFiltered as string,
      },
      eventDeviceUser: {
        value: deviceUserFiltered,
        label: deviceUserFiltered as string,
      },
    }
    return r
  }, [
    filterTableValues,
    isFromDate,
    isToDate,
    dateRangeOptions,
    deviceUserParam,
    eventDescriptionParam,
    filterLogsDateFrom,
    filterLogsDateTo,
  ])

  // Discard this function - soon to be removed
  // const filterEventLogs = (formFilterValues: IFilterValues[]) => {
  //   // if date range is wider than set state date range, then update state
  //   // this will udpate query key and fetch new data
  //   // do not do a local filter now?

  //   // If date range is narrower than set state date range, then do a local filter
  //   const filterValueDateRange = formFilterValues.find(
  //     (filterValue) => filterValue.columnId === 'eventDate',
  //   )?.value as DateRange
  //   const isDateRangeNarrower =
  //     (isAfter(new Date(filterValueDateRange.startDate), new Date(eventLogFromToDates.startDate)) ||
  //       isEqual(
  //         new Date(filterValueDateRange.startDate),
  //         new Date(eventLogFromToDates.startDate),
  //       )) &&
  //     (isBefore(new Date(eventLogFromToDates.endDate), new Date(filterValueDateRange.endDate)) ||
  //       isEqual(new Date(eventLogFromToDates.endDate), new Date(filterValueDateRange.endDate)))

  //   if (isDateRangeNarrower) {
  //     setFilterValues(formFilterValues)
  //   } else {
  //     const { startDate, endDate } = filterValueDateRange

  //     // navigate(`/devices/${deviceSerialNumber}/eventlog?from=${startDate}&to=${endDate}`)

  //     setSearchParams({
  //       from: new Date(startDate).toISOString().split('T')[0],
  //       to: new Date(endDate).toISOString().split('T')[0],
  //     })
  //   }
  // }

  const showFilter = () => {
    dispatch({
      type: DeviceDetailContextActions.ShowFilterForm,
      payload: {
        page: DeviceDetailPageTabs.EventLog,
        filterFormData: {
          onFilterSubmit: (formData: IFilterValues[]) => handleFilterFormSubmit(formData),
          onFilterReset: () => resetFilterFormParams(),
          onFilterFormClose: () => setShowFilterForm(false),
          selectedValues: convertISelectOptionPropsToFormType,
          deviceUsersData: filterFormDeviceUserValues,
          eventDescriptionsData: filterFormDescriptionValues,
        },
      },
    })
  }

  const hideFilterForm = () => {
    dispatch({ type: DeviceDetailContextActions.HideFilterForm })
  }

  // useEffect(() => {
  //   if (is2xlAndAbove) {
  //     showFilter()
  //   } else {
  //     hideFilterForm()
  //   }
  // }, [is2xlAndAbove])

  return (
    <>
      <ExportData
        handleCancel={() => setShowExportDialog(false)}
        IsOpen={showExportDialog}
        data={eventLogsTranslated || []}
        reportColumns={EventLogsReportColumns}
        widgetForReport={[]}
        reportTitle='Device event logs'
        filterSummary={EventLogsFilterFormat(
          filterTableValues,
          deviceDetails?.serialNumber || '',
          deviceDetails?.deviceType || '',
        )}
      />

      <TsTable<iDeviceEventLog>
        data={eventLogsTranslated}
        dataIsLoading={isLoadingDeviceEventLogs}
        columns={deviceEventLogTableColumns}
        showGlobalActionButton={false}
        globalActionButton={<NavigateBackButton />}
        showGlobalFilter={false}
        getRowCanExpand={() => false}
        onPrint={() => {}}
        onExport={() => {}}
        defaultSortedColumKey={columnIDs.eventDate}
        updateFilteredData={() => {}}
        renderFilterSummary={() => <> </>}
        filterValues={filterTableValues}
        onShowFilter={() => showFilter()}
        resetFilter={() => resetFilterFormParams()}
        minDisplayRows={10}
        renderTabs={() => <DeviceDetailsReportSelector />}
        setShowExportDialog={(val) => setShowExportDialog(val)} // disabling export dialog action
      />
      {/* <div
          id='eventlogfilterform'
          className={clsx(
            negativeTopMargin,
            `z-50 transition duration-1000 delay-1000 2xl:ml-0 fixed top-0 right-0`,
            state.filterFormShown ? 'block -ml-80 ' : 'hidden 2xl:block ',
          )}
        >
          <DeviceEventLogFilterForm
            onFilterSubmit={filterEventLogs}
            onFilterReset={() => {}}
            onFilterFormClose={() => setShowFilterForm(false)}
            selectedValues={filterFormSelectedValues}
            deviceUsersData={filterFormDeviceUserValues}
            eventDescriptionsData={filterFormDescriptionValues}
          />

        </div> */}

      {/* )} */}
    </>
  )
}

export default withErrorBoundary(withPageTracking(DeviceEventLogs), {
  FallbackComponent: ErrorPage,
})
