import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { isAfter, isBefore, isEqual, sub } from 'date-fns'
import { useDeviceDetails } from 'pages/deviceDetails/rootPage/view/DeviceDetailsRoot'
import { ColumnDef } from '@tanstack/react-table'
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
import { DateRangeColumnFilterFn, dateRangeOptions, selectAllOption } from 'forms/FormUtils'
import TsTable from 'components/table/TsTable'
import { DateRangeSelectorTypes, DeviceDetailPageTabs } from 'utils/CommonEnums'
import {
  DeviceLogUploadClients,
  DeviceLogUploadTypes,
  ScreenWidths,
  returnDateWithoutTimeOffset,
} from 'utils/Constants'
import {
  DeviceDetailContextActions,
  DeviceDetailsContext,
} from 'pages/deviceDetails/rootPage/view/DeviceDetailsContext'
import ExportData from 'exportReports/ExportData'
import Tooltip from 'components/atom/Tooltip'
import { withPageTracking } from 'utils/AppInsightConfig'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import DeviceDetailsReportSelector from 'pages/deviceDetails/rootPage/view/DeviceDetailsReportSelector'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { NavigateBackButton } from '../../../rootPage/view/NavigateBackButton'
import { iUploadHistory } from '../data/interface'
import { useDeviceSyncHistoryData } from '../data/DeviceSyncHistoryData'
import { SyncHistoryReportColumns } from '../export/SyncHistoryReportFormat'
import SyncHistoryFilterFormat from '../export/SyncHistoryFilterFormat'

export const columnIDs = {
  uploadDate: 'uploadDate',
  uploadDataType: 'uploadLogType',
  uploadClientType: 'clientType',
}

export type DeviceSyncHistoryFilterFormType = {
  uploadDateSelector: IselectOptionProps
  uploadDate: DateRange
  uploadDataType: IselectOptionProps
  uploadClientType: IselectOptionProps
}

const DeviceSyncHistoryFilterParamLabels = {
  uploadDateFrom: 'from',
  uploadDateTo: 'to',
  uploadDataType: 'datatype',
  uploadClientType: 'client',
}

function DeviceSyncHistory() {
  // Implement the component logic here

  const [is2xlAndAbove, setIs2xlAndAbove] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIs2xlAndAbove(window.innerWidth >= ScreenWidths.xxl)
    }

    handleResize() // Initial check

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const deviceDetailsContext = useContext(DeviceDetailsContext)
  const { dispatch } = deviceDetailsContext

  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`

  const [searchParams, setSearchParams] = useSearchParams()
  const [showExportDialog, setShowExportDialog] = React.useState(false)

  /**
   * Get the query params from the URL
   * @param {string} isFromDate - The from date query param
   * @param {string} isToDate - The to date query param
   * @param {string} uploadDateTo - The to date
   * @param {string} uploadDateFrom - The from date
   */
  const isFromDate = searchParams.get(DeviceSyncHistoryFilterParamLabels.uploadDateFrom) || ''
  const isToDate = searchParams.get(DeviceSyncHistoryFilterParamLabels.uploadDateTo) || ''

  const uploadDateTo = returnDateWithoutTimeOffset(isToDate)
  const uploadDateFrom = returnDateWithoutTimeOffset(
    isFromDate || sub(new Date(uploadDateTo), { months: 6 }),
  )
  const uploadDataTypeParam =
    searchParams.get(DeviceSyncHistoryFilterParamLabels.uploadDataType) ?? ''
  const uploadClientTypeParam =
    searchParams.get(DeviceSyncHistoryFilterParamLabels.uploadClientType) ?? ''

  const params = useParams()
  const { deviceSerialNumber } = params as { deviceSerialNumber: string }
  document.title = `Sync History ${deviceSerialNumber}`

  const { deviceDetails } = useDeviceDetails()
  const { id: deviceId } = deviceDetails ?? { deviceId: '' }

  const [deviceLogUploadTypesInData, setDeviceLogUploadTypesInData] = React.useState<
    IselectOptionProps[]
  >([])
  const [deviceLogUploadClientsInData, setDeviceLogUploadClientsInData] = React.useState<
    IselectOptionProps[]
  >([])

  const { genericEventHandler } = useGenericEventHandler()
  const {
    data: deviceSyncHistory,
    isLoading: isDeviceSyncHistoryLoading,
    isError: isDeviceSyncHistoryError,
    error: deviceSyncHistoryError,
  } = useDeviceSyncHistoryData(
    deviceId as string,
    uploadDateFrom.toISOString().split('T')[0],
    uploadDateTo.toISOString().split('T')[0],
    redirectPageURL,
    true,
  )

  useEffect(() => {
    if (isDeviceSyncHistoryError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        message: deviceSyncHistoryError?.message || 'Error getting device sync history',
        error: deviceSyncHistoryError,
        extraData: {
          component: 'DeviceSyncHistory',
          action: 'get device sync history',
        },
      })
    }
  }, [isDeviceSyncHistoryError, deviceSyncHistoryError])

  useEffect(() => {
    if (deviceSyncHistory) {
      const uploadTypes: IselectOptionProps[] = []
      const uploadClients: IselectOptionProps[] = []

      DeviceLogUploadTypes.forEach((type) => {
        if (deviceSyncHistory.find((log) => log.uploadLogType === type.value)) {
          uploadTypes.push({
            label: type.label,
            value: type.value,
            isDisabled: false,
          })
        } else {
          uploadTypes.push({
            label: type.label,
            value: type.value,
            isDisabled: true,
          })
        }
      })

      uploadTypes.unshift(selectAllOption)

      DeviceLogUploadClients.forEach((client) => {
        if (deviceSyncHistory.find((log) => log.clientType === client.value)) {
          uploadClients.push({
            label: client.label,
            value: client.value,
            isDisabled: false,
          })
        } else {
          uploadClients.push({
            label: client.label,
            value: client.value,
            isDisabled: true,
          })
        }
      })

      uploadClients.unshift(selectAllOption)

      setDeviceLogUploadTypesInData(uploadTypes)
      setDeviceLogUploadClientsInData(uploadClients)
    }
  }, [deviceSyncHistory])

  const deviceSyncHistoryColumns = useMemo<ColumnDef<iUploadHistory>[]>(
    () => [
      {
        id: columnIDs.uploadDate,
        header: 'Date',
        accessorKey: 'uploadDate',
        cell: (info) => {
          const value: string = info.getValue() as string
          const date = new Date(value)
          return (
            <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
              <span className='mr-2'>
                <FormattedDate value={date} />
              </span>
              <span>
                <FormattedTime value={date} />
              </span>
            </div>
          )
        },

        meta: {},
        filterFn: DateRangeColumnFilterFn<iUploadHistory>,
      },

      {
        id: columnIDs.uploadDataType,
        header: 'Synced data',
        accessorKey: 'uploadLogType',
        cell: (info) => {
          const value: string = info.getValue() as string
          const logType = DeviceLogUploadTypes.find((log) => log.value === value)
          return (
            <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1  '>
              <FormattedMessage id={logType?.label ?? ''} defaultMessage={logType?.label} />
            </div>
          )
        },
        meta: {},
      },
      {
        id: columnIDs.uploadClientType,
        header: 'Synced via',
        accessorKey: 'clientType',
        cell: (info) => {
          const value: string = info.getValue() as string
          const clientType = DeviceLogUploadClients.find((client) => client.value === value)
          return (
            <Tooltip
              id={`device-sync-history-client-type-${clientType?.label}`}
              showOnlyWhenTextIsTruncate
              tooltipText={clientType?.label ?? ''}
              toolTipClass='tooltip'
              className='font-poppins text-2xs leading-4 font-semibold text-c-dark-blue-1 '
            >
              {clientType?.label}
            </Tooltip>
          )
        },
        meta: {},
      },
    ],
    [],
  )

  const resetFilterFormParams = () => {
    searchParams.delete(DeviceSyncHistoryFilterParamLabels.uploadDateFrom)
    searchParams.delete(DeviceSyncHistoryFilterParamLabels.uploadDateTo)
    searchParams.delete(DeviceSyncHistoryFilterParamLabels.uploadDataType)
    searchParams.delete(DeviceSyncHistoryFilterParamLabels.uploadClientType)
    setSearchParams(searchParams)
  }

  const handleFilterFormSubmit = useCallback(
    (formData: IFilterValues[]) => {
      formData.forEach((filter) => {
        if (filter.columnId === columnIDs.uploadClientType) {
          if (filter.value === '') {
            searchParams.delete(DeviceSyncHistoryFilterParamLabels.uploadClientType)
          } else {
            searchParams.set(
              DeviceSyncHistoryFilterParamLabels.uploadClientType,
              filter.value as string,
            )
          }
        }

        if (filter.columnId === columnIDs.uploadDataType) {
          if (filter.value === '') {
            searchParams.delete(DeviceSyncHistoryFilterParamLabels.uploadDataType)
          } else {
            searchParams.set(
              DeviceSyncHistoryFilterParamLabels.uploadDataType,
              filter.value as string,
            )
          }
        }

        if (filter.columnId === columnIDs.uploadDate) {
          if (filter.value === '') {
            searchParams.delete(DeviceSyncHistoryFilterParamLabels.uploadDateFrom)
            searchParams.delete(DeviceSyncHistoryFilterParamLabels.uploadDateTo)
          } else {
            const dateRangeToBeFiltered = filter?.value as DateRange
            // Calculate if DateRange is wider based on below code
            const isFromBeforeOrSame =
              isBefore(new Date(dateRangeToBeFiltered.startDate), new Date(uploadDateFrom)) ||
              isEqual(new Date(dateRangeToBeFiltered.startDate), new Date(uploadDateFrom))

            const isToAfterOrSame =
              isAfter(new Date(dateRangeToBeFiltered.endDate), new Date(uploadDateTo)) ||
              isEqual(new Date(dateRangeToBeFiltered.endDate), new Date(uploadDateTo))

            const isDateRangeWider = isFromBeforeOrSame && isToAfterOrSame

            if (isDateRangeWider) {
              searchParams.set(
                DeviceSyncHistoryFilterParamLabels.uploadDateFrom,
                new Date(dateRangeToBeFiltered.startDate).toISOString().split('T')[0],
              )

              searchParams.set(
                DeviceSyncHistoryFilterParamLabels.uploadDateTo,
                new Date(dateRangeToBeFiltered.endDate).toISOString().split('T')[0],
              )
            }

            searchParams.set(
              DeviceSyncHistoryFilterParamLabels.uploadDateFrom,
              new Date(dateRangeToBeFiltered.startDate).toISOString().split('T')[0],
            )

            searchParams.set(
              DeviceSyncHistoryFilterParamLabels.uploadDateTo,
              new Date(dateRangeToBeFiltered.endDate).toISOString().split('T')[0],
            )
          }
        }
      })
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  const filterTableValues = useMemo(
    () => [
      {
        columnId: columnIDs.uploadDate,
        value:
          isFromDate && isToDate
            ? {
                startDate: new Date(uploadDateFrom),
                endDate: new Date(uploadDateTo),
              }
            : '',
      },
      {
        columnId: columnIDs.uploadDataType,
        value: uploadDataTypeParam ?? '',
      },
      {
        columnId: columnIDs.uploadClientType,
        value: uploadClientTypeParam ?? '',
      },
    ],
    [
      uploadDateFrom,
      uploadDateTo,
      uploadDataTypeParam,
      uploadClientTypeParam,
      isFromDate,
      isToDate,
    ],
  )

  const convertISelectOptionPropsToFormType = useMemo(() => {
    const uploadClientTypeFiltered = filterTableValues.find(
      (filter) => filter.columnId === columnIDs.uploadClientType,
    )?.value as string

    const uploadDataTypeFiltered = filterTableValues.find(
      (filter) => filter.columnId === columnIDs.uploadDataType,
    )?.value as string

    const uploadDateFiltered = (filterTableValues.find(
      (filter) => filter.columnId === columnIDs.uploadDate,
    )?.value || {
      startDate: uploadDateFrom,
      endDate: uploadDateTo,
    }) as DateRange

    const r: DeviceSyncHistoryFilterFormType = {
      uploadDateSelector: dateRangeOptions.find(
        (option) => option.value === DateRangeSelectorTypes.Custom,
      ) ?? {
        label: '',
        value: '',
      },

      uploadDate: {
        startDate: new Date(uploadDateFiltered.startDate),
        endDate: new Date(uploadDateFiltered.endDate),
      },

      uploadDataType: deviceLogUploadTypesInData.find(
        (type) => type.value === uploadDataTypeFiltered,
      ) ?? {
        label: '',
        value: '',
      },

      uploadClientType: deviceLogUploadClientsInData.find(
        (client) => client.value === uploadClientTypeFiltered,
      ) ?? {
        label: '',
        value: '',
      },
    }
  }, [filterTableValues])

  const showFilter = () => {
    dispatch({
      type: DeviceDetailContextActions.ShowFilterForm,
      payload: {
        page: DeviceDetailPageTabs.SyncHistory,
        filterFormData: {
          onFilterSubmit: (formData: IFilterValues[]) => handleFilterFormSubmit(formData),
          onFilterReset: () => resetFilterFormParams(),
          // onFilterFormClose: () => setShowFilterForm(false),
          selectedValues: convertISelectOptionPropsToFormType,
          uploadDataTypes: deviceLogUploadTypesInData,
          uploadClientTypes: deviceLogUploadClientsInData,
        },
      },
    })
  }

  const hideFilterForm = () => {
    dispatch({ type: DeviceDetailContextActions.HideFilterForm })
  }

  // useEffect(() => {
  //   if (is2xlAndAbove) {
  //     showFilter()
  //   } else {
  //     hideFilterForm()
  //   }
  // }, [is2xlAndAbove])

  // const filterSyncHistory = (formFilterValues: IFilterValues[]) => {
  //   const filterValueDateRange = formFilterValues.find(
  //     (filterValue) => filterValue.columnId === columnIDs.uploadDate,
  //   )?.value as DateRange

  //   const isDateRangeNarrower =
  //     (isAfter(
  //       new Date(filterValueDateRange.startDate),
  //       new Date(syncHistoryFromToDates.startDate),
  //     ) ||
  //       isEqual(
  //         new Date(filterValueDateRange.startDate),
  //         new Date(syncHistoryFromToDates.startDate),
  //       )) &&
  //     (isBefore(new Date(syncHistoryFromToDates.endDate), new Date(filterValueDateRange.endDate)) ||
  //       isEqual(new Date(syncHistoryFromToDates.endDate), new Date(filterValueDateRange.endDate)))

  //   if (isDateRangeNarrower) {
  //     setFilterValues(formFilterValues)
  //   } else {
  //     const { startDate, endDate } = filterValueDateRange

  //     // navigate(`/devices/${deviceSerialNumber}/eventlog?from=${startDate}&to=${endDate}`)

  //     setSearchParams({
  //       from: new Date(startDate).toISOString().split('T')[0],
  //       to: new Date(endDate).toISOString().split('T')[0],
  //     })
  //   }
  // }

  // Return the JSX that will be rendered
  return (
    <>
      {/* <div className={clsx('flex flex-row ', state.filterFormShown && '')}> */}
      <ExportData
        handleCancel={() => setShowExportDialog(false)}
        IsOpen={showExportDialog}
        data={deviceSyncHistory ?? []}
        reportColumns={SyncHistoryReportColumns}
        widgetForReport={[]}
        reportTitle='Device sync history'
        filterSummary={SyncHistoryFilterFormat(
          filterTableValues,
          deviceDetails?.serialNumber || '',
          deviceDetails?.deviceType || '',
        )}
      />
      {/* <div className='flex-grow overflow-x-auto'> */}
      <TsTable<iUploadHistory>
        data={deviceSyncHistory ?? []}
        columns={deviceSyncHistoryColumns}
        showGlobalActionButton={false}
        globalActionButton={<NavigateBackButton />}
        showGlobalFilter={false}
        getRowCanExpand={() => false}
        onPrint={() => {}}
        onExport={() => {}}
        onShowFilter={showFilter}
        dataIsLoading={isDeviceSyncHistoryLoading}
        resetFilter={() => resetFilterFormParams()}
        updateFilteredData={() => {}}
        renderFilterSummary={() => <> </>}
        filterValues={filterTableValues}
        minDisplayRows={10}
        defaultSortedColumKey={columnIDs.uploadDate}
        renderTabs={() => <DeviceDetailsReportSelector />}
        setShowExportDialog={(val) => setShowExportDialog(val)}
      />
      {/* </div> */}
      {/* <DeviceSyncHistoryFilterForm
          onFilterSubmit={filterSyncHistory}
          onFilterReset={() => {}}
          onFilterFormClose={() => {}}
          selectedValues={filterFormSelectedValues}
          uploadDataTypes={deviceLogUploadTypesInData}
          uploadClientTypes={deviceLogUploadClientsInData}
        /> */}
      {/* </div> */}
    </>
  )
}

export default withErrorBoundary(withPageTracking(DeviceSyncHistory), {
  FallbackComponent: ErrorPage,
})
