import { IPublicClientApplication } from '@azure/msal-browser'
import { iCustomerList } from 'data/CustomerListHook'
import { iBusinessUnitDetails } from 'data/Interfaces'
import {
  createDemoCustomerUrl,
  createDemoDevicesUrl,
  createDemoEventsLogsUrl,
  createDemoGasLogsUrl,
  createDemoOperatorsUrl,
  demoDeviceConfigurationUrl,
  getDemoBusUrl,
  getDemoCustomerUrl,
} from 'services/apis/urls/azure-functions-urls'
import { azureFunctionCaller } from 'services/azure-functions'
import { getAccessTokenFromPCA } from 'services/MSALService'
import { iDeviceConfig } from 'data/DeviceConfigHook'
import { iDevice } from 'data/DeviceListHook'
import {
  DemoDevicesConfigPayload,
  DemoDevicesCreationResponse,
  DemoOperators,
  EventLogsPayload,
  GasLogsPayload,
} from '../../interfaces'

type MSALInstanceType = {
  msalInstance: IPublicClientApplication
}

export async function getDemoCustomer({ msalInstance }: MSALInstanceType) {
  const url = getDemoCustomerUrl()
  const token = await getAccessTokenFromPCA({ msalInstance })
  // await new Promise((resolve) => setTimeout(resolve, 5000))
  const { data } = await azureFunctionCaller<iCustomerList, unknown>(url, token, undefined)
  // setDataForUser((pre: DataForUserType) => ({
  //   ...pre,
  //   customerId: data.id,
  // }))

  return data
}

export async function createDemoCustomer({ msalInstance }: MSALInstanceType) {
  const url = createDemoCustomerUrl()
  const token = await getAccessTokenFromPCA({ msalInstance })
  // await new Promise((resolve) => setTimeout(resolve, 5000))
  const { data } = await azureFunctionCaller<string, { Name: string }>(
    url,
    token,
    {
      Name: 'Demo Customer',
    },
    'POST',
  )
  // setDataForUser((pre: DataForUserType) => ({
  //   ...pre,
  //   customerId: data,
  // }))

  return data
}

export async function getDemoBusinessUnit({
  customerId,
  msalInstance,
}: {
  customerId: string
  msalInstance: IPublicClientApplication
}) {
  const url = getDemoBusUrl(customerId)
  const token = await getAccessTokenFromPCA({ msalInstance })
  // await new Promise((resolve) => setTimeout(resolve, 5000))
  const { data } = await azureFunctionCaller<iBusinessUnitDetails[], unknown>(url, token, undefined)
  // const bus = data.find((bu) => !bu.parentId) as iBusinessUnitDetails
  // setDataForUser((pre: DataForUserType) => ({
  //   ...pre,
  //   businessUnitId: bus.id as string,
  //   businessUnitName: bus.name as string,
  // }))

  return data
}

export async function getDemoOperators({
  customerId,
  businessUnitId,
  msalInstance,
}: { customerId: string; businessUnitId: string } & MSALInstanceType) {
  const demoOperatorUrl = createDemoOperatorsUrl()
  const token = await getAccessTokenFromPCA({ msalInstance })
  // await new Promise((resolve) => setTimeout(resolve, 5000))
  const { data } = await azureFunctionCaller<DemoOperators, unknown>(
    demoOperatorUrl,
    token,
    {
      customerId,
      businessUnitId,
    },
    'POST',
  )
  return data
}

export async function getDemoDevices({
  customerId,
  businessUnitId,
  operatorId,
  msalInstance,
}: { customerId: string; businessUnitId: string; operatorId?: string } & MSALInstanceType) {
  const demoDeviceUrl = createDemoDevicesUrl()
  const token = await getAccessTokenFromPCA({ msalInstance })
  //   await new Promise((resolve) => setTimeout(resolve, 5000))
  const { data } = await azureFunctionCaller<DemoDevicesCreationResponse, unknown>(
    demoDeviceUrl,
    token,
    {
      customerId,
      businessUnitId,
      operatorId,
    },
    'POST',
  )

  return data
}

export async function setChannelConfig({
  device,
  msalInstance,
}: { device: DemoDevicesCreationResponse } & MSALInstanceType) {
  const setConfigurationUrl = demoDeviceConfigurationUrl()
  const token = await getAccessTokenFromPCA({ msalInstance })
  //   await new Promise((resolve) => setTimeout(resolve, 5000))
  const { data: configuration } = await azureFunctionCaller<
    iDeviceConfig,
    DemoDevicesConfigPayload
  >(setConfigurationUrl, token, { device }, 'POST')
  return configuration
}

export async function getDemoLogs({
  configuration,
  numberOfRecords,
  timeDurationInMonths,
  msalInstance,
}: {
  configuration?: iDeviceConfig
  numberOfRecords: number
  timeDurationInMonths: number
} & MSALInstanceType) {
  const eventLogPayload: EventLogsPayload = {
    device: {
      deviceId: configuration?.deviceId as string,
      numberOfActiveChannels: configuration?.numberOfActiveChannels as number,
    },
    numberOfRecords,
    timeDurationInMonths,
  }

  const gasLogPayload: GasLogsPayload = {
    device: configuration as iDeviceConfig,
    numberOfRecords,
    timeDurationInMonths,
  }

  const eventLogUrl = createDemoEventsLogsUrl()
  const token = await getAccessTokenFromPCA({ msalInstance })
  //   await new Promise((resolve) => setTimeout(resolve, 5000))
  const { data } = await azureFunctionCaller<unknown, EventLogsPayload>(
    eventLogUrl,
    token,
    eventLogPayload,
    'POST',
  )

  if (data) {
    const gasLogUrl = createDemoGasLogsUrl()
    // await new Promise((resolve) => setTimeout(resolve, 5000))
    await azureFunctionCaller<iDevice[], GasLogsPayload>(gasLogUrl, token, gasLogPayload, 'POST')
  }
  return data
}
